import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, Button, Modal, TextField, Grid, IconButton, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import Search from './Search';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import SyncIcon from '@mui/icons-material/Sync';
import ButtonBase from '@mui/material/ButtonBase';

const Channels = () => {
  const [channels, setChannels] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newChannel, setNewChannel] = useState({
    channel_id: '',
    channel_name: '',
    channel_link: '',
  
    channel_handle: ''
  });
  const [editingChannel, setEditingChannel] = useState(null);
  const [editChannelDetails, setEditChannelDetails] = useState({
    channel_id: '',
    channel_name: '',
    channel_link: '',
    channel_handle: ''
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Details'); // Selected tab state
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const baseUrl  = process.env.REACT_APP_BACKEND_URL;

  const handleInfo = (channel) => {
    console.log("Setting selected tab to 'Details'");
    setSelectedChannel(channel);
    setSelectedTab('Details'); // Set default tab to Details
    setOpenInfoModal(true);
  };
  


  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
  };

  useEffect(() => {
    fetchChannels();
  }, []);

  const fetchChannels = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      console.log('Token:', token);
  
      // Use the environment variable for the base URL
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      
      // Construct the request URL
      const requestUrl = `${baseUrl}api/channels/`;
      
      // Log the request URL
      console.log('Request URL:', requestUrl);
      
      // Make the request
      const response = await axios.get(requestUrl, {
        headers: {
          'Authorization': `Token ${token}`,
        },
      });
  
      const fetchedChannels = response.data.results;
  
      setChannels(fetchedChannels);
      setFilteredChannels(fetchedChannels);
    } catch (error) {
      console.error('Error Fetching channels:', error);
    } finally {
      setLoading(false); // Ensure loading is set to false after the fetch
    }
  };
  
  console.log('Videos:', channels);
  console.log('Filtered Videos:', filteredChannels);

  const handleSearch = (searchParams) => {
    const { channel } = searchParams;
    let filtered = [...channels];
    
    if (typeof channel === 'string') {
      // Ensure channel is not empty before filtering
      const normalizedChannelName = channel.trim().toLowerCase(); // Normalize for case-insensitive comparison
      if (normalizedChannelName) {
        filtered = filtered.filter(channel =>
          channel.channel_name.toLowerCase().includes(normalizedChannelName)
        );
      }
    }
    
    setFilteredChannels(filtered);
  };
  
  const handleCreateChannel = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/channels/`, newChannel, 
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      setOpenModal(false);
      fetchChannels();
      // Reset the fields
      setNewChannel({
        channel_id: '',
        channel_name: '',
        channel_link: '',
        channel_handle: ''
      });
    } catch (error) {
      console.error('Error creating channel:', error.response.data);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewChannel(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCloseModal = () => {
    // Reset the fields
    setNewChannel({
      channel_id: '',
      channel_name: '',
      channel_link: '',
      date_created: '',
      date_last_updated: '',
      channel_handle: ''
    });
    setOpenModal(false);
  };

  const handleEditChannel = (channel) => {
    setEditingChannel(channel);
    setEditChannelDetails({ ...channel }); // Set editChannelDetails with channel data
    setEditModalOpen(true);
  };

  const handleUpdateChannel = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/channels/${editingChannel.id}/`, editChannelDetails, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      // After successful update, close the modal and refresh channel data
      setEditModalOpen(false);
      fetchChannels();
    } catch (error) {
      console.error('Error updating channel:', error.response.data);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditChannelDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDetailChannel = async (e) => {
    setSelectedTab('Details'); // Switch to Details tab
  };

  const handleDocumentChannel = async (e) => {
    setSelectedTab('Documents'); // Switch to Documents tab
  };


  const handleVideoChannel = async () => {
    setSelectedTab('Videos'); // Switch to Videos tab

    setLoading(true);
    
    // Check if there is a selected channel and it has video IDs
    if (selectedChannel && selectedChannel.video_ids) {
      const videoIds = selectedChannel.video_ids.split('\n');
      const updatedVideos = [];
      
      // Iterate through each video ID
      for (const videoId of videoIds) {
        try {
          // Fetch channel details based on the video ID
          const channelResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/get-channel-name/`, {
            video_id: videoId
          });
          const channelDetails = channelResponse.data;
  
          // Log the channel name
          console.log('Channel Name:', channelDetails.channel_name);
          console.log(channelDetails);
          
          // Push the fetched video details to the updated videos array
          updatedVideos.push({
            videoId: videoId,
            channel_name: channelDetails.channel_name,
            title: channelDetails.title,
            upload_date: channelDetails.upload_date
        });
        
        } catch (error) {
          console.error(`Error fetching channel details for video ID ${videoId}:`, error);
        }
      }
      
      // Update the selected channel with the fetched video details
      setSelectedChannel(prevState => ({
        ...prevState,
        videos: updatedVideos
      }));
    }
    setLoading(false);
  };

  
  
  
  
  
  

  const handleSyncChannel = async (channelId, channelObjId) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-videos-from-channel/`, {
        channel_ids:channelId,
        channel_id: channelId,
        channel: channelObjId
      });
      // Refresh channels after syncing
      fetchChannels();
    } catch (error) {
      console.error('Error syncing channel:', error.response.data);
    }
  };


  const handleHistoryChannel = async (channelId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/channels/history/${channelId}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      setHistoryData(response.data);
      console.log(response.data);
      setSelectedTab('History');
    } catch (error) {
      console.error('Error fetching channel history:', error.response.data);
    }
  };



// Assuming selectedChannel is your channel data
// if (selectedChannel && selectedChannel.video_ids) {
//   const videoIds = selectedChannel.video_ids.split('\n');
  
//   // Iterate through each video ID
//   videoIds.forEach(async (videoId) => {
//     try {
//       const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&key=AIzaSyDi-ZZzzVjaWQpRv3sxdzraoueQO6mZPOU`);
//       const videoDetails = response.data.items[0].snippet;
      
//       // Now, you have access to video title and other related information
//       // console.log(videoDetails.title);
//       // console.log(videoDetails.publishedAt); // Upload date
//       // // Add other related information as needed
      
//     } catch (error) {
      // console.error(`Error fetching video details for video ID ${videoId}:`, error);
//     }
//   });
// }
const handleOpenVideosTab = () => {
  setSelectedTab('Videos');
  setOpenInfoModal(true);
};

useEffect(() => {
  console.log("Selected tab:", selectedTab);
}, [selectedTab]);

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="create-channel-modal-title"
        aria-describedby="create-channel-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height:'80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <IconButton onClick={handleCloseModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <h2 id="create-channel-modal-title">Create Channel</h2>
          <TextField
            name="channel_id"
            label="Channel ID"
            value={newChannel.channel_id}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="channel_handle"
            label="Channel Handle"
            value={newChannel.channel_handle}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="channel_name"
            label="Channel Name"
            value={newChannel.channel_name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="channel_link"
            label="Channel Link"
            value={newChannel.channel_link}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <Button onClick={handleCreateChannel} variant="contained" color="primary">Create Channel</Button>
        </Box>
      </Modal>
      <Box>
        <Search onSearch={handleSearch} />
        <Grid container justifyContent="flex-end" marginTop="0px" marginRight="5px">
          <Grid item>
            <Button onClick={() => setOpenModal(true)} variant="contained" style={{ marginBottom: '02px' }}>Create Channel</Button>
          </Grid>
        </Grid>
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: '#e0e0e0' }}>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>S No</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Channel Handle</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Views</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Videos</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Subscribers</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredChannels.map((channel, index) => (
                <TableRow key={channel.id}>
                  <TableCell style={{ padding: '0.01px' }}>{index + 1}</TableCell>
                  <TableCell style={{ padding: '0.01px' }}>
                    <a href={`https://www.youtube.com/@${channel.channel_handle}`} target="_blank" rel="noopener noreferrer">{channel.channel_handle}</a>
                  </TableCell>
                  <TableCell style={{ padding: '0.01px' }}>{channel.channel_name}</TableCell>
                  <TableCell style={{ padding: '0.01px' }}>{channel.channel_views}</TableCell>
                  <TableCell style={{ padding: '0.01px' }}>
                  <ButtonBase
  onClick={() => handleOpenVideosTab(channel)}
  style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
>
  {channel.channel_videos}
</ButtonBase>

</TableCell>
                  <TableCell style={{ padding: '0.01px' }}>{channel.channel_subs}</TableCell>
                  <TableCell style={{ padding: '0.01px' }}>
                    {/* <IconButton onClick={() => handleSyncChannel(channel.channel_id, channel.id)} aria-label="sync">
                      <SyncIcon />
                    </IconButton> */}
                    <IconButton onClick={() => handleEditChannel(channel)} aria-label="edit">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleInfo(channel)} aria-label="info">
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="edit-channel-modal-title"
        aria-describedby="edit-channel-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: '80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <IconButton onClick={() => setEditModalOpen(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <h2 id="edit-channel-modal-title">Edit Channel</h2>
          {editingChannel && (
            <>
              <TextField
                name="channel_id"
                label="Channel ID"
                value={editChannelDetails.channel_id}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="channel_name"
                label="Channel Name"
                value={editChannelDetails.channel_name}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />

<TextField
                name="channel_handle"
                label="Channel handle"
                value={editChannelDetails.channel_handle}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              
              <TextField
                name="channel_link"
                label="Channel Link"
                value={editChannelDetails.channel_link}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
             <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'15px' }}>
  <Button onClick={handleUpdateChannel} variant="contained" color="primary">Update </Button>
  <Button onClick={() => setEditModalOpen(false)} variant="contained" color="primary">Cancel</Button>
</div>

            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={openInfoModal}
        onClose={handleCloseInfoModal}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          height: '80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <IconButton onClick={handleCloseInfoModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          {selectedChannel && (
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button onClick={handleDetailChannel} style={{ color: selectedTab === 'Details' ? 'blue' : 'inherit' }}>Details</Button>
<Button onClick={handleDocumentChannel} style={{ color: selectedTab === 'Documents' ? 'blue' : 'inherit' }}>Documents</Button>
<Button onClick={handleVideoChannel} style={{ color: selectedTab === 'Videos' ? 'blue' : 'inherit' }}>Videos</Button>
<Button onClick={() => handleHistoryChannel(selectedChannel.channel_id)} style={{ color: selectedTab === 'History' ? 'blue' : 'inherit' }}>History</Button>



              </div>
              {selectedTab === 'Details' && (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{fontWeight: 'bold'}}>Channel ID:</TableCell>
                      <TableCell>{selectedChannel.channel_id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{fontWeight:'bold'}}>Channel Name:</TableCell>
                      <TableCell>{selectedChannel.channel_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{fontWeight:'bold'}}>Channel Link:</TableCell>
                      <TableCell>{selectedChannel.channel_link}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{fontWeight:'bold'}}>Channel Handle:</TableCell>
                      <TableCell>{selectedChannel.channel_handle}</TableCell>
                    </TableRow>
                    
                    {/* <TableRow>
                      <TableCell>Date Created:</TableCell>
                      <TableCell>{selectedChannel.date_created}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Date Last Updated:</TableCell>
                      <TableCell>{selectedChannel.date_last_updated}</TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              )}
              {selectedTab === 'Documents' && (
                <Typography variant="body1">Document Tab Content</Typography>
              )}
               {selectedTab === 'History' && historyData.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
             
            <TableCell sx={{ fontWeight: 'bold' }}>Channel</TableCell>
      <TableCell sx={{ fontWeight: 'bold' }}>Views</TableCell>
      <TableCell sx={{ fontWeight: 'bold' }}>Subscribers</TableCell>
      <TableCell sx={{ fontWeight: 'bold' }}>Videos</TableCell>
      <TableCell sx={{ fontWeight: 'bold' }}>Date Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData.map((historyItem) => (
              <TableRow key={historyItem.id}>
                <TableCell>{historyItem.channel}</TableCell>
                <TableCell>{historyItem.views}</TableCell>
                <TableCell>{historyItem.subs}</TableCell>
                <TableCell>{historyItem.videos}</TableCell>
                <TableCell>{historyItem.date_updated}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}


{selectedTab === 'Videos' ? (
  selectedChannel && selectedChannel.videos ? (
    selectedChannel.videos.length > 0 ? (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Video ID</TableCell>
            <TableCell>Channel Name</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Upload Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedChannel.videos.map((video, index) => (
            <TableRow key={index}>
              <TableCell>
                <a href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer">
                  {video.videoId}
                </a>
              </TableCell>
              <TableCell>
                {/* Render channel name */}
                {video.channel_name}
              </TableCell>
              <TableCell>
                {/* Render video title */}
                {video.title}
              </TableCell>
              <TableCell>
                {/* Render video upload date */}
                {new Date(video.upload_date).toLocaleDateString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <div>No videos available</div>
    )
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
      <CircularProgress />
    </div>
  )
) : null}





            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Channels;
