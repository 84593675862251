import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, Button, Modal, TextField, Grid, IconButton,Select, MenuItem, Typography  } from '@mui/material';
import AdminSearch from './AdminSearch';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

const AdminUser = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchParams, setSearchParams] = useState({
    firstName: '',
    lastName: '',
    userId: '',
    telephoneNumber: '',
    email: '',
    location: '',
    language: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '', 
    phone_number_1: '',
    location: '',
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [editUserDetails, setEditUserDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number_1: '',
    phone_number_2: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    user_id: '',
    location: '',
    status: '',
    last_updated: '',
  });

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/users/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      setUsers(response.data);
      setFilteredUsers(response.data); // Initialize filtered users with all users
    } catch (error) {
      console.error('Error Fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    // If all search parameters are empty, load all data
    const isAnySearchParamNotEmpty = Object.values(searchParams).some(param => param.trim() !== '');
    if (!isAnySearchParamNotEmpty) {
      setFilteredUsers(users);
    }
  }, [searchParams, users]);

  const handleSearch = (searchParams) => {
    setSearchParams(searchParams);
  
    const { firstName, lastName, userId, telephoneNumber, email, location } = searchParams;
    let filtered = users;
  
    if (firstName) {
      filtered = filtered.filter(user => user.first_name.toLowerCase().startsWith(firstName.toLowerCase()));
    }
  
    if (lastName) {
      filtered = filtered.filter(user => user.last_name.toLowerCase().startsWith(lastName.toLowerCase()));
    }
  
    if (userId) {
      filtered = filtered.filter(user => user.user_id.toString().startsWith(userId));
    }
  
    if (telephoneNumber) {
      filtered = filtered.filter(user => user.phone_number_1.startsWith(telephoneNumber));
    }
  
    if (email) {
      filtered = filtered.filter(user => user.email.toLowerCase().startsWith(email.toLowerCase()));
    }
    
    if (location){
      filtered = filtered.filter(user => user.location && user.location.toLowerCase().startsWith(location.toLowerCase()));
    }
    
    setFilteredUsers(filtered);
  };

  const handleCreateUser = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/register/`, newUser, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      // After successful creation, close the modal and refresh user data
      setOpenModal(false);
      setSearchParams({ ...searchParams });
      fetchUsers(); // Fetch users again after creating a new user
    } catch (error) {
      console.error('Error creating user:', error.response.data);
    }
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setEditUserDetails({ ...user }); // Set editUserDetails with user data
    setEditModalOpen(true);
  };

  // Function to update the user details
  const handleUpdateUser = async () => {
    try {
      const token = localStorage.getItem('token');
      const { password, ...updatedUserData } = editUserDetails; // Exclude password field
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/users/users/${editingUser.user_id}/`, updatedUserData, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      // After successful update, close the modal and refresh user data
      setEditModalOpen(false);
      setSearchParams({ ...searchParams });
      fetchUsers();
    } catch (error) {
      console.error('Error updating user:', error.response.data);
    }
  };
  
  
  
  
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditUserDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const [openInfoModal, setOpenInfoModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const handleInfo = (user) => {
        setSelectedUser(user);
        setOpenInfoModal(true);
      };
  
  
    
      const handleCloseInfoModal = () => {
        setOpenInfoModal(false);
      };

  return (
    <div>
      
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="create-user-modal-title"
        aria-describedby="create-user-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height:'80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
           <IconButton onClick={() => setOpenModal(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <h2 id="create-user-modal-title">Create User</h2>
          <TextField
            name="first_name"
            label="First Name"
            value={newUser.first_name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="last_name"
            label="Last Name"
            value={newUser.last_name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="email"
            label="Email"
            value={newUser.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            value={newUser.password}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          
          <TextField
            name="phone_number_1"
            label="Phone Number"
            value={newUser.phone_number_1}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <Typography>Location</Typography>
             <Select
              label="Location"
              name="location"
              value={newUser.location}
              placeholder='location'
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Thanjavur">Thanjavur</MenuItem>
              <MenuItem value="Mumbai">Mumbai</MenuItem>
              <MenuItem value="Indore">Indore</MenuItem>
            </Select>
          <Box display="flex" justifyContent="flex-end" marginTop="20px">
          <Button onClick={handleCreateUser} variant="contained" color="primary">Create User</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="edit-user-modal-title"
        aria-describedby="edit-user-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height:'80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <h2 id="edit-user-modal-title">Edit User</h2>
          {editingUser && (
            <>
              <TextField
                name="first_name"
                label="First Name"
                value={editUserDetails.first_name}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="last_name"
                label="Last Name"
                value={editUserDetails.last_name}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="email"
                label="Email"
                value={editUserDetails.email}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="phone_number_1"
                label="Phone Number 1"
                value={editUserDetails.phone_number_1}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="phone_number_2"
                label="Phone Number 2"
                value={editUserDetails.phone_number_2}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="address_1"
                label="Address 1"
                value={editUserDetails.address_1}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="address_2"
                label="Address 2"
                value={editUserDetails.address_2}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="city"
                label="City"
                value={editUserDetails.city}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="state"
                label="State"
                value={editUserDetails.state}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="country"
                label="Country"
                value={editUserDetails.country}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="pin_code"
                label="Pin Code"
                value={editUserDetails.pin_code}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <Typography>Location</Typography>
             <Select
              label="Location"
              name="location"
              value={editUserDetails.location}
              placeholder='location'
              onChange={handleEditInputChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Thanjavur">Thanjavur</MenuItem>
              <MenuItem value="Mumbai">Mumbai</MenuItem>
              <MenuItem value="Indore">Indore</MenuItem>
            </Select>

              {/* <TextField
                name="location"
                label="Location"
                value={editUserDetails.location}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              /> */}
              <TextField
                name="status"
                label="Status"
                value={editUserDetails.status}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="last_updated"
                label="Last Updated"
                value={editUserDetails.last_updated}
                onChange={handleEditInputChange}
                fullWidth
                margin="normal"
              />
              <Button onClick={handleUpdateUser} variant="contained" color="primary">Update User</Button>
            </>
          )}
        </Box>
      </Modal>
      <Box marginTop="10px">
        <AdminSearch onSearch={handleSearch} />
        <Grid container justifyContent="flex-end" marginTop="0px" marginRight="5px">
        <Grid item>
          <Button onClick={() => setOpenModal(true)} variant="contained"   style={{ marginBottom: '10px' }}>Create User</Button>
        </Grid>
      </Grid>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: '#e0e0e0' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Serial No</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>First Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Last Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>User ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Phone Number</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Date Created</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Location</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Actions</TableCell> {/* New column for actions */}
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredUsers.map((user, index) => (
              <TableRow key={user.user_id}>
                <TableCell style={{ padding: '0.1px' }}>{index + 1}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{user.first_name}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{user.last_name}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{user.user_id}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{user.phone_number_1}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>
    {user.last_updated ? new Date(user.last_updated).toLocaleString() : '-'}
  </TableCell>
                <TableCell style={{ padding: '0.1px' }}>{user.location}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>
                  <IconButton onClick={() => handleEditUser(user)} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleInfo(user)} aria-label="info">
                      <InfoIcon />
                    </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
  open={openInfoModal}
  onClose={handleCloseInfoModal}
  aria-labelledby="user-info-modal-title"
  aria-describedby="user-info-modal-description"
>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
  }}>
    <IconButton onClick={handleCloseInfoModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
      <CloseIcon />
    </IconButton>
    {selectedUser && (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button> Details</Button>
          <Button> Documents</Button>
          <Button> History</Button>
        </div>

        <Table style={{ marginTop: '5px' }}>
          <TableBody>
            <TableRow>
              <TableCell>First Name:</TableCell>
              <TableCell>{selectedUser.first_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Name:</TableCell>
              <TableCell>{selectedUser.last_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email:</TableCell>
              <TableCell>{selectedUser.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone Number 1:</TableCell>
              <TableCell>{selectedUser.phone_number_1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone Number 2:</TableCell>
              <TableCell>{selectedUser.phone_number_2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address 1:</TableCell>
              <TableCell>{selectedUser.address_1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address 2:</TableCell>
              <TableCell>{selectedUser.address_2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>City:</TableCell>
              <TableCell>{selectedUser.city}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>State:</TableCell>
              <TableCell>{selectedUser.state}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Country:</TableCell>
              <TableCell>{selectedUser.country}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pin Code:</TableCell>
              <TableCell>{selectedUser.pin_code}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>User ID:</TableCell>
              <TableCell>{selectedUser.user_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Location:</TableCell>
              <TableCell>{selectedUser.location}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status:</TableCell>
              <TableCell>{selectedUser.status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Updated:</TableCell>
              <TableCell>{selectedUser.last_updated}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )}
  </Box>
</Modal>

        </div>
      </Box>
    </div>
  );
};

export default AdminUser;
