import React, { useState } from 'react';
import { TextField, Box, Typography, Checkbox } from '@mui/material';
import AdminSearch from './AdminSearch';

const AdminPermissions = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  return (
    <Box marginTop={1} display="flex" flexDirection="column">
       <AdminSearch/>
      <Box display="flex" alignItems="center" marginTop={4}>
        <Typography htmlFor="first-name" variant="subtitle1" component="label" style={{ marginLeft: '10px' }}>
          First Name:
        </Typography>
        <TextField
          id="first-name"
          variant="standard"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <Typography htmlFor="last-name" variant="subtitle1" component="label" style={{ marginLeft: '100px' }}>
          Last Name:
        </Typography>
        <TextField
          id="last-name"
          variant="standard"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          style={{ marginBottom: '10px', marginLeft: '10px' }}
        />
      </Box>
      <Box marginTop={4}>
        <Box border="0.5px solid darkblue" padding={2} marginLeft={1}>
          <Typography variant="h6" fontWeight="bold" gutterBottom textAlign="center">
            Modules:
          </Typography>
          {['Channels', 'Videos', 'Subscribers', 'Reports'].map((module, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h6">{module}</Typography>
              <div style={{ display: 'flex' }}>
                <Checkbox color="primary" id={`read-${module}`} />
                <label htmlFor={`read-${module}`}>Read</label>
                <Checkbox color="primary" id={`update-${module}`} />
                <label htmlFor={`update-${module}`}>Update</label>
                <Checkbox color="primary" id={`create-${module}`} />
                <label htmlFor={`create-${module}`}>Create</label>
              </div>
            </div>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default AdminPermissions;
