import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Login from './components/Login';
import AdminPermissions from './components/admin/AdminPermissions';
import AdminFetch from './components/admin/AdminFetch';
import AdminUser from './components/admin/AdminUser';
import AdminLocation from './components/admin/AdminLocation';
import LoginHistory from './components/LoginHistory';
import Search from './components/Search';
import AdminSearch from './components/admin/AdminSearch';
import Channels from './components/Channels';
import Videos from './components/Videos';
import Artists from './components/Artists';
import Subscribers from './components/Subscribers';
import Element from './components/vendors';
import Sponsors from './components/Sponsers';
import MailScreen from './components/MailScreen';
import PasswordReset from './components/PasswordReset';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token); // Set isAuthenticated based on token presence
  }, []);

  return (
    <div>
      <Router> {/* Ensure that the App component is wrapped inside a Router component */}
        <Header />
        <Routes>
          {/* Redirect to /login initially */}
          <Route path="/" element={<Navigate to="/login" replace />} />

          {/* Login route */}
          <Route path="/login" element={<Login />} />

          {/* Home route (protected route) */}
          <Route 
            path="/home" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Home />
              </ProtectedRoute>
            } 
          />

          {/* Admin routes (only accessible when authenticated) */}
          <Route 
            path="/admin/*" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <>
                  <Header withAdminSearch />
                  <AdminPermissions />
                </>
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin/fetch" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AdminFetch />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin/users" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AdminUser />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin/location" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AdminLocation />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin/login-history" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <LoginHistory />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin/permissions" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AdminPermissions />
              </ProtectedRoute>
            } 
          />

          {/* Public routes */}
          <Route path="/channels" element={<Channels />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/subscribers" element={<Subscribers />} />
          <Route path="/vendors" element={<Element />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/mailScreen" element={<MailScreen />} />
          <Route path="/passwordReset" element={<PasswordReset />} />
          
          {/* Render Search component for other routes except for the mailScreen route */}
          {isAuthenticated && <Route path="/*" element={<Search />} />}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
