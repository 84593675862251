import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, Button, Modal, TextField, Typography, IconButton, MenuItem, Select, FormControl, InputLabel,Grid } from '@mui/material';
import axios from 'axios';
import Search from './Search'; // If you have a reusable search component
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
const Vendors = () => {
    const [vendors, setVendors] = useState([]);
    const [filteredVendors, setFilteredVendors] = useState([]);
    const [searchParams, setSearchParams] = useState({
        vendor_name: '',
        contact_name: '',
        language: '',
        phone: '',
        email: '',
        last_updated: '',
        vendor_company_name: '',
        vendor_lastname: '',
        language_spoken: '',
        date_first_hired: '',
        category_hired: '',
        vendor_website: '',
        vendor_gst_number: '',
        phone_number1: '',
        phone_number2: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        location: ''
    });
    const [openModal, setOpenModal] = useState(false);
    const [newVendor, setNewVendor] = useState({
        vendor_name: '',
        contact_name: '',
        language: '',
        phone: '',
        email: '',
        last_updated: '',
        vendor_company_name: '',
        vendor_lastname: '',
        language_spoken: '',
        date_first_hired: '',
        category_hired: '',
        vendor_website: '',
        vendor_gst_number: '',
        phone_number1: '',
        phone_number2: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        location: ''
    });
    const [editingVendor, setEditingVendor] = useState(null);
    const [editVendorDetails, setEditVendorDetails] = useState({
        vendor_name: '',
        contact_name: '',
        language: '',
        phone: '',
        email: '',
        last_updated: '',
        vendor_company_name: '',
        vendor_lastname: '',
        language_spoken: '',
        date_first_hired: '',
        category_hired: '',
        vendor_website: '',
        vendor_gst_number: '',
        phone_number1: '',
        phone_number2: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        location: '',
    });
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchVendors = async () => {
      try {
        setLoading(true); // Set loading state to true before the fetch starts
        const token = localStorage.getItem('token');
        
        if (!token) {
          throw new Error('Token not found. Please log in again.');
        }
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/vendors/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        
        // Assuming response.data.results is the array you want to use for mapping
        const fetchedVendors = response.data.results;
        
        // Update the state with the fetched data
        setVendors(fetchedVendors);
        setFilteredVendors(fetchedVendors); // Initialize filteredVendors with fetched data
    
      } catch (error) {
        console.error('Error fetching vendors:', error);
        // Optionally, provide user feedback here
        alert('Failed to fetch vendors. Please try again later.');
      } finally {
        setLoading(false); // Set loading state to false after the fetch completes
      }
    };
    
    useEffect(() => {
        fetchVendors();
    }, []);
    const locationOptions = ['Mumbai', 'Thanjavur', 'Indore'];

   const handleSearch = (searchParams) => {
    const { vendor_name, vendor_email } = searchParams; // Add vendor_email to the destructuring
    console.log('Searching for vendor name:', vendor_name);
    console.log('Searching for vendor email:', vendor_email); // Log the vendor email
    console.log('All vendors:', vendors);
  
    let filtered = vendors;
  
    // Filter by vendor name
    if (typeof vendor_name === 'string') {
      const normalizedVendorName = vendor_name.trim().toLowerCase();
      if (normalizedVendorName) {
        filtered = filtered.filter(vendor =>
          vendor.vendor_name && vendor.vendor_name.toLowerCase().includes(normalizedVendorName)
        );
      }
    }

    // Filter by vendor email
    if (typeof vendor_email === 'string') {
      const normalizedVendorEmail = vendor_email.trim().toLowerCase();
      if (normalizedVendorEmail) {
        filtered = filtered.filter(vendor =>
          vendor.vendor_email && vendor.vendor_email.toLowerCase().includes(normalizedVendorEmail)
        );
      }
    }
  
    console.log('Filtered vendors:', filtered);
    setFilteredVendors(filtered);
};

    
    

    const handleCreateVendor = async () => {
        try {
            const { vendor_name, contact_name, language, email,location } = newVendor;
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/vendors/`, { vendor_name, contact_name, language, email,location });
            setOpenModal(false);
            fetchVendors();
            setNewVendor({
                vendor_name: '',
                contact_name: '',
                language: '',
                phone: '',
                email: '',
                last_updated: '',
                vendor_company_name: '',
                vendor_lastname: '',
                language_spoken: '',
                date_first_hired: '',
                category_hired: '',
                vendor_website: '',
                vendor_gst_number: '',
                phone_number1: '',
                phone_number2: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zipcode: '',
                location: ''
            });
        } catch (error) {
            console.error('Error creating vendor:', error.response.data);
        }
    };
    
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewVendor(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleEditVendor = (vendor) => {
        setEditingVendor(vendor);
        setEditVendorDetails({ ...vendor });
        setEditModalOpen(true);
    };

    const handleUpdateVendor = async () => {
        try {
            const { vendor_name, contact_name, language, email } = editVendorDetails;
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/vendors/${editingVendor.id}/`, { vendor_name, contact_name, language, email });
            setEditModalOpen(false);
            fetchVendors();
        } catch (error) {
            console.error('Error updating vendor:', error.response.data);
        }
    };
    

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditVendorDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Helper function to format date-time string
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString(); // Adjust as needed for the desired format
    };

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const handleInfo = (vendor) => {
        setSelectedVendor(vendor);
        setOpenInfoModal(true);
      };
  
  
    
      const handleCloseInfoModal = () => {
        setOpenInfoModal(false);
      };

    return (
        <div>
            <Modal
    open={openModal}
    onClose={() => setOpenModal(false)}
    aria-labelledby="create-vendor-modal-title"
    aria-describedby="create-vendor-modal-description"
>
    <Box
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            height: '80vh',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
        }}
    >
      <IconButton onClick={() => setOpenModal(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
        {/* Modal content for creating a new vendor */}
        <Typography variant="h5">Create Vendor</Typography>
        <TextField
            label="Vendor Name"
            variant="outlined"
            name="vendor_name"
            value={newVendor.vendor_name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
        />
        <TextField
            label="Contact Name"
            variant="outlined"
            name="contact_name"
            value={newVendor.contact_name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
        />
        <TextField
            label="Language"
            variant="outlined"
            name="language"
            value={newVendor.language}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
        />
        <TextField
            label="Phone"
            variant="outlined"
            name="phone"
            value={newVendor.phone}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
        />
        <TextField
            label="Email"
            variant="outlined"
            name="email"
            value={newVendor.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
        />
       

<FormControl fullWidth margin="normal">
            <InputLabel>Location</InputLabel>
            <Select
              value={newVendor.location}
              onChange={handleInputChange}
              name="location"
            >
              {locationOptions.map((location, index) => (
                <MenuItem key={index} value={location}>{location}</MenuItem>
              ))}
            </Select>
          </FormControl>
        {/* Add more text fields for other vendor details */}
        <Box display="flex" justifyContent="flex-end">
        <Button onClick={handleCreateVendor} variant="contained">Create</Button>
        </Box>
    </Box>
</Modal>


            <Box>
                {/* Search component */}
                <Search onSearch={handleSearch} />
                <Grid container justifyContent="flex-end" marginTop="0px" marginRight="5px">
        <Grid item>
          <Button onClick={() => setOpenModal(true)} variant="contained"   style={{ marginBottom: '02px' }}>Create Vendor</Button>
        </Grid>
      </Grid>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Table>
    {/* Table header */}
    <TableHead>
    <TableRow sx={{ bgcolor: '#e0e0e0' }}>
            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Serial No</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Vendor Name</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Contact Name</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Language</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Location</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Email</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Last Updated</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Action</TableCell>
        </TableRow>
    </TableHead>
    <TableBody>
        {filteredVendors.map((vendor,index) => (
            <TableRow key={vendor.id}>
                {/* Table cells for each vendor field */}
                <TableCell style={{ padding: '0.1px' }}>{index + 1}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{vendor.vendor_name}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{vendor.contact_name}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{vendor.language}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{vendor.location}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{vendor.email}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>{formatDate(vendor.last_updated)}</TableCell>
                <TableCell style={{ padding: '0.1px' }}>
                    <IconButton onClick={() => handleEditVendor(vendor)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleInfo(vendor)} aria-label="info">
                      <InfoIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        ))}
    </TableBody>
</Table>
</div>

            </Box>
            <Modal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                aria-labelledby="edit-vendor-modal-title"
                aria-describedby="edit-vendor-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        height: '80vh',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                    }}
                >
                    {/* Modal content for editing a vendor */}
                    <Typography variant="h5">Edit Vendor</Typography>
<TextField
    label="Vendor Name"
    variant="outlined"
    name="vendor_name"
    value={editVendorDetails.vendor_name}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Contact Name"
    variant="outlined"
    name="contact_name"
    value={editVendorDetails.contact_name}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Language"
    variant="outlined"
    name="language"
    value={editVendorDetails.language}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Phone"
    variant="outlined"
    name="phone"
    value={editVendorDetails.phone}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Email"
    variant="outlined"
    name="email"
    value={editVendorDetails.email}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Vendor Company Name"
    variant="outlined"
    name="vendor_company_name"
    value={editVendorDetails.vendor_company_name}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Vendor Last Name"
    variant="outlined"
    name="vendor_lastname"
    value={editVendorDetails.vendor_lastname}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Language Spoken"
    variant="outlined"
    name="language_spoken"
    value={editVendorDetails.language_spoken}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Date First Hired"
    variant="outlined"
    name="date_first_hired"
    type="date"
    value={editVendorDetails.date_first_hired}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
    InputLabelProps={{
        shrink: true,
    }}
/>
<TextField
    label="Category Hired"
    variant="outlined"
    name="category_hired"
    value={editVendorDetails.category_hired}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Vendor Website"
    variant="outlined"
    name="vendor_website"
    value={editVendorDetails.vendor_website}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Vendor GST Number"
    variant="outlined"
    name="vendor_gst_number"
    value={editVendorDetails.vendor_gst_number}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Phone Number 1"
    variant="outlined"
    name="phone_number1"
    value={editVendorDetails.phone_number1}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Phone Number 2"
    variant="outlined"
    name="phone_number2"
    value={editVendorDetails.phone_number2}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Address 1"
    variant="outlined"
    name="address1"
    value={editVendorDetails.address1}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Address 2"
    variant="outlined"
    name="address2"
    value={editVendorDetails.address2}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="City"
    variant="outlined"
    name="city"
    value={editVendorDetails.city}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="State"
    variant="outlined"
    name="state"
    value={editVendorDetails.state}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>
<TextField
    label="Zipcode"
    variant="outlined"
    name="zipcode"
    value={editVendorDetails.zipcode}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
/>

<FormControl fullWidth margin="normal">
            <InputLabel>Location</InputLabel>
            <Select
              value={newVendor.location}
              onChange={handleInputChange}
              name="location"
            >
              {locationOptions.map((location, index) => (
                <MenuItem key={index} value={location}>{location}</MenuItem>
              ))}
            </Select>
          </FormControl>

                    {/* Add other text fields for editing vendor details */}
                    <Button onClick={handleUpdateVendor} variant="contained">Update</Button>
                </Box>
            </Modal>
            <Modal
  open={openInfoModal}
  onClose={handleCloseInfoModal}
  aria-labelledby="info-modal-title"
  aria-describedby="info-modal-description"
>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
  }}>
    <IconButton onClick={handleCloseInfoModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
      <CloseIcon />
    </IconButton>
    {selectedVendor && (
      <div>
        <div style={{display:'flex',flexDirection:'row',justifyContent: 'space-between'}}>
      <Button> Details</Button>
      <Button> Documents</Button>
      <Button> History</Button>
    </div>
        <Table style={{marginTop:'5px'}}>
          <TableBody>
            <TableRow>
              <TableCell>Vendor ID:</TableCell>
              <TableCell>{selectedVendor.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vendor Name:</TableCell>
              <TableCell>{selectedVendor.vendor_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact Name:</TableCell>
              <TableCell>{selectedVendor.contact_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email:</TableCell>
              <TableCell>{selectedVendor.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Language:</TableCell>
              <TableCell>{selectedVendor.language}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone:</TableCell>
              <TableCell>{selectedVendor.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Updated:</TableCell>
              <TableCell>{selectedVendor.last_updated}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date First Hired:</TableCell>
              <TableCell>{selectedVendor.date_first_hired}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Zipcode:</TableCell>
              <TableCell>{selectedVendor.zipcode}</TableCell>
            </TableRow>
            {/* Add more rows for additional fields as needed */}
          </TableBody>
        </Table>
      </div>
    )}
  </Box>
</Modal>

        </div>
    );
};

export default Vendors;
