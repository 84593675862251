import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, Button, Modal, TextField, Typography, IconButton, MenuItem, Select, FormControl, InputLabel,Grid } from '@mui/material';
import axios from 'axios';
import Search from './Search'; // If you have a reusable search component
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
const Sponsors = () => {
    const [sponsors, setSponsors] = useState([]);
    const [filteredSponsors, setFilteredSponsors] = useState([]);
    const [searchParams, setSearchParams] = useState({
        sponsor_name: '',
        contact_name: '',
        language: '',
        phone: '',
        email: '',
        last_updated: '',
        sponsor_company_name: '',
        sponsor_lastname: '',
        language_spoken: '',
        date_first_hired: '',
        category_hired: '',
        sponsor_website: '',
        sponsor_gst_number: '',
        phone_number1: '',
        phone_number2: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
    });
    const [openModal, setOpenModal] = useState(false);
    const [newSponsor, setNewSponsor] = useState({
        sponsor_name: '',
        contact_name: '',
        language: '',
        phone: '',
        email: '',
        last_updated: '',
        sponsor_company_name: '',
        sponsor_lastname: '',
        language_spoken: '',
        date_first_hired: '',
        category_hired: '',
        sponsor_website: '',
        sponsor_gst_number: '',
        phone_number1: '',
        phone_number2: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        location:''
    });
    const [editingSponsor, setEditingSponsor] = useState(null);
    const [editSponsorDetails, setEditSponsorDetails] = useState({
        sponsor_name: '',
        contact_name: '',
        language: '',
        phone: '',
        email: '',
        last_updated: '',
        sponsor_company_name: '',
        sponsor_lastname: '',
        language_spoken: '',
        date_first_hired: '',
        category_hired: '',
        sponsor_website: '',
        sponsor_gst_number: '',
        phone_number1: '',
        phone_number2: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        location:''
    });
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const locationOptions = ['Mumbai', 'Thanjavur', 'Indore'];

    const fetchSponsors = async () => {
        try {
          setLoading(true); // Set loading state to true before the fetch starts
          const token = localStorage.getItem('token');
          
          if (!token) {
            throw new Error('Token not found. Please log in again.');
          }
          
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sponsors/`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          });
          
          // Assuming response.data.results is the array you want to use for mapping
          const fetchedSponsors = response.data.results;
          
          // Update the state with the fetched data
          setSponsors(fetchedSponsors);
          setFilteredSponsors(fetchedSponsors); // Initialize filteredSponsors with fetched data
      
        } catch (error) {
          console.error('Error fetching sponsors:', error);
          // Optionally, provide user feedback here
          alert('Failed to fetch sponsors. Please try again later.');
        } finally {
          setLoading(false); // Set loading state to false after the fetch completes
        }
      };
      

    useEffect(() => {
        fetchSponsors();
    }, []);

    const handleSearch = (searchParams) => {
        setSearchParams(searchParams);

        let filtered = sponsors;

        Object.entries(searchParams).forEach(([key, value]) => {
            if (value) {
                filtered = filtered.filter(sponsor => sponsor[key].toString().toLowerCase().startsWith(value.toLowerCase()));
            }
        });

        setFilteredSponsors(filtered);
    };

    const handleCreateSponsor = async () => {
        try {
            const { sponsor_name, contact_name, language, email } = newSponsor;
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sponsors/`, { sponsor_name, contact_name, language, email });
            setOpenModal(false);
            fetchSponsors();


            setNewSponsor({
                sponsor_name: '',
                contact_name: '',
                language: '',
                phone: '',
                email: '',
                last_updated: '',
                sponsor_company_name: '',
                sponsor_lastname: '',
                language_spoken: '',
                date_first_hired: '',
                category_hired: '',
                sponsor_website: '',
                sponsor_gst_number: '',
                phone_number1: '',
                phone_number2: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zipcode: '',
                location: ''
            });
        } catch (error) {
            console.error('Error creating sponsor:', error.response.data);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSponsor(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleEditSponsor = (sponsor) => {
        setEditingSponsor(sponsor);
        setEditSponsorDetails({ ...sponsor });
        setEditModalOpen(true);
    };

    const handleUpdateSponsor = async () => {
        try {
            const {
                sponsor_name,
                contact_name,
                language,
                phone,
                email,
                sponsor_company_name,
                sponsor_lastname,
                language_spoken,
                date_first_hired,
                category_hired,
                sponsor_website,
                sponsor_gst_number,
                phone_number1,
                phone_number2,
                address1,
                address2,
                city,
                state,
                zipcode,
                location
            } = editSponsorDetails;
    
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/sponsors/${editingSponsor.id}/`, {
                sponsor_name,
                contact_name,
                language,
                phone,
                email,
                sponsor_company_name,
                sponsor_lastname,
                language_spoken,
                date_first_hired,
                category_hired,
                sponsor_website,
                sponsor_gst_number,
                phone_number1,
                phone_number2,
                address1,
                address2,
                city,
                state,
                zipcode,
                location
            });
    
            setEditModalOpen(false);
            fetchSponsors();
        } catch (error) {
            console.error('Error updating sponsor:', error.response.data);
        }
    };
    

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditSponsorDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Helper function to format date-time string
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString(); // Adjust as needed for the desired format
    };

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [selectedSponsor, setSelectedSponsor] = useState(null);
    const handleInfo = (sponsor) => {
        setSelectedSponsor(sponsor);
        setOpenInfoModal(true);
      };
  
  
    
      const handleCloseInfoModal = () => {
        setOpenInfoModal(false);
      };

      const handleLocationChange = (e) => {
        const { name, value } = e.target;
        setNewSponsor(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    return (
        <div>
           
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="create-sponsor-modal-title"
                aria-describedby="create-sponsor-modal-description"
            >
                
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        height: '80vh',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                    }}
                >
                    <IconButton onClick={() => setOpenModal(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
                    {/* Modal content for creating a new sponsor */}
                    <Typography variant="h5">Create Sponsor</Typography>
                    <TextField
                        label="sponsor Name"
                        variant="outlined"
                        name="sponsor_name"
                        value={newSponsor.sponsor_name}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Contact Name"
                        variant="outlined"
                        name="contact_name"
                        value={newSponsor.contact_name}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Language"
                        variant="outlined"
                        name="language"
                        value={newSponsor.language}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        value={newSponsor.phone}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={newSponsor.email}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Last Updated"
                        variant="outlined"
                        type="date"
                        name="last_updated"
                        value={newSponsor.last_updated}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                     <FormControl fullWidth margin="normal">
            <InputLabel>Location</InputLabel>
            <Select
              value={newSponsor.location}
              onChange={handleInputChange}
              name="location"
            >
              {locationOptions.map((location, index) => (
                <MenuItem key={index} value={location}>{location}</MenuItem>
              ))}
            </Select>
          </FormControl>
                    {/* Add more text fields for other sponsor details */}
                    <Box display="flex" justifyContent="flex-end">
                    <Button onClick={handleCreateSponsor} variant="contained">Create</Button>
                    </Box>
                </Box>
            </Modal>
            <Box marginTop="0px">
                {/* Search component */}
                <Search onSearch={handleSearch} />
                <Grid container justifyContent="flex-end" marginTop="0px" marginRight="5px">
        <Grid item>
          <Button onClick={() => setOpenModal(true)} variant="contained"   style={{ marginBottom: '02px' }}>Create Sponsor</Button>
        </Grid>
      </Grid>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Table>
                    {/* Table header */}
                    <TableHead>
                    <TableRow sx={{ bgcolor: '#e0e0e0' }}>
                            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Serial No</TableCell>
                            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Vendor Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Contact Name</TableCell>
                            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Language</TableCell>
                            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Location</TableCell>
                            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Email</TableCell>
                            <TableCell  sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Last Updated</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredSponsors.map((sponsor, index) => (
                            <TableRow key={sponsor.id}>
                                {/* Table cells for each sponsor field */}
                                <TableCell style={{ padding: '0.1px' }}>{index + 1}</TableCell>
                                <TableCell style={{ padding: '0.1px' }}>{sponsor.sponsor_name}</TableCell>
                                <TableCell style={{ padding: '0.1px' }}>{sponsor.contact_name}</TableCell>
                                <TableCell style={{ padding: '0.1px' }}>{sponsor.language}</TableCell>
                                <TableCell style={{ padding: '0.1px' }}>{sponsor.location}</TableCell>
                                <TableCell style={{ padding: '0.1px' }}>{sponsor.email}</TableCell>
                                <TableCell style={{ padding: '0.1px' }}>{formatDate(sponsor.last_updated)}</TableCell>
                                <TableCell style={{ padding: '0.1px' }}>
                                    <IconButton onClick={() => handleEditSponsor(sponsor)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleInfo(sponsor)} aria-label="info">
                      <InfoIcon />
                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </div>
            </Box>
            <Modal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                aria-labelledby="edit-sponsor-modal-title"
                aria-describedby="edit-sponsor-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        height: '80vh',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                    }}
                >
                    <IconButton onClick={() => setOpenModal(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
                    {/* Modal content for editing a sponsor */}
                    <Typography variant="h5">Edit Sponsor</Typography>
                    <TextField
                        label="Sponsor Name"
                        variant="outlined"
                        name="sponsor_name"
                        value={editSponsorDetails.sponsor_name}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Contact Name"
                        variant="outlined"
                        name="contact_name"
                        value={editSponsorDetails.contact_name}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Language"
                        variant="outlined"
                        name="language"
                        value={editSponsorDetails.language}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        value={editSponsorDetails.phone}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={editSponsorDetails.email}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Sponsor Company Name"
                        variant="outlined"
                        name="sponsor_company_name"
                        value={editSponsorDetails.sponsor_company_name}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="sponsor Last Name"
                        variant="outlined"
                        name="sponsor_lastname"
                        value={editSponsorDetails.sponsor_lastname}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Language Spoken"
                        variant="outlined"
                        name="language_spoken"
                        value={editSponsorDetails.language_spoken}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Date First Hired"
                        variant="outlined"
                        name="date_first_hired"
                        type="date"
                        value={editSponsorDetails.date_first_hired}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        label="Category Hired"
                        variant="outlined"
                        name="category_hired"
                        value={editSponsorDetails.category_hired}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="sponsor Website"
                        variant="outlined"
                        name="sponsor_website"
                        value={editSponsorDetails.sponsor_website}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="sponsor GST Number"
                        variant="outlined"
                        name="sponsor_gst_number"
                        value={editSponsorDetails.sponsor_gst_number}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Phone Number 1"
                        variant="outlined"
                        name="phone_number1"
                        value={editSponsorDetails.phone_number1}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Phone Number 2"
                        variant="outlined"
                        name="phone_number2"
                        value={editSponsorDetails.phone_number2}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Address 1"
                        variant="outlined"
                        name="address1"
                        value={editSponsorDetails.address1}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Address 2"
                        variant="outlined"
                        name="address2"
                        value={editSponsorDetails.address2}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="City"
                        variant="outlined"
                        name="city"
                        value={editSponsorDetails.city}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="State"
                        variant="outlined"
                        name="state"
                        value={editSponsorDetails.state}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Zipcode"
                        variant="outlined"
                        name="zipcode"
                        value={editSponsorDetails.zipcode}
                        onChange={handleEditInputChange}
                        fullWidth
                        margin="normal"
                    />

             

<FormControl fullWidth margin="normal">
    <InputLabel>Location</InputLabel>
    <Select
        value={newSponsor.location}
        onChange={handleLocationChange}  // Use the new function to handle location changes
        name="location"
    >
        {locationOptions.map((location, index) => (
            <MenuItem key={index} value={location}>{location}</MenuItem>
        ))}
    </Select>
</FormControl>

                    {/* Add other text fields for editing sponsor details */}
                    <Button onClick={handleUpdateSponsor} variant="contained">Update</Button>
                </Box>
            </Modal>
            <Modal
  open={openInfoModal}
  onClose={handleCloseInfoModal}
  aria-labelledby="info-modal-title"
  aria-describedby="info-modal-description"
>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
  }}>
    <IconButton onClick={handleCloseInfoModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
      <CloseIcon />
    </IconButton>
    {selectedSponsor && (
      <div>
         <div style={{display:'flex',flexDirection:'row',justifyContent: 'space-between'}}>
      <Button> Details</Button>
      <Button> Documents</Button>
      <Button> History</Button>
    </div>
        <Table style={{marginTop:'5px'}}>
          <TableBody>
            <TableRow>
              <TableCell>Sponsor ID:</TableCell>
              <TableCell>{selectedSponsor.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sponsor Name:</TableCell>
              <TableCell>{selectedSponsor.sponsor_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact Name:</TableCell>
              <TableCell>{selectedSponsor.contact_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email:</TableCell>
              <TableCell>{selectedSponsor.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Language:</TableCell>
              <TableCell>{selectedSponsor.language}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone:</TableCell>
              <TableCell>{selectedSponsor.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Updated:</TableCell>
              <TableCell>{selectedSponsor.last_updated}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date First Hired:</TableCell>
              <TableCell>{selectedSponsor.date_first_hired}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Zipcode:</TableCell>
              <TableCell>{selectedSponsor.zipcode}</TableCell>
            </TableRow>
            {/* Add more rows for additional fields as needed */}
          </TableBody>
        </Table>
      </div>
    )}
  </Box>
</Modal>

        </div>
    );
};

export default Sponsors;
