import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import AdminSearch from './AdminSearch';
import axios from 'axios';

const AdminLocation = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchParams, setSearchParams] = useState({
    firstName: '',
    lastName: '',
    userId: '',
    telephoneNumber: '',
    email: '',
    location: '',
    language: '',
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/users/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        const data = response.data;
        setUsers(data);
        setFilteredUsers(data);
      } catch (error) {
        console.error('Error Fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (searchParams) => {
    setSearchParams(searchParams);

    let filtered = users;

    const { firstName, lastName, userId, telephoneNumber, email, location } = searchParams;

    if (firstName) {
      filtered = filtered.filter(user => user.first_name.toLowerCase().startsWith(firstName.toLowerCase()));
    }

    if (lastName) {
      filtered = filtered.filter(user => user.last_name.toLowerCase().startsWith(lastName.toLowerCase()));
    }

    if (userId) {
      filtered = filtered.filter(user => user.user_id.toString().startsWith(userId));
    }

    if (telephoneNumber) {
      filtered = filtered.filter(user => user.phone_number_1.startsWith(telephoneNumber));
    }

    if (email) {
      filtered = filtered.filter(user => user.email.toLowerCase().startsWith(email.toLowerCase()));
    }

    if (location) {
      filtered = filtered.filter(user => user.location && user.location.toLowerCase().startsWith(location.toLowerCase()));
    }

    setFilteredUsers(filtered);
  };

  return (
    <Paper marginTop="10px">
      <AdminSearch onSearch={handleSearch} />
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <Table>
        <TableHead>
        <TableRow sx={{ bgcolor: '#e0e0e0' }}>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>User Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>User ID</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.map((user) => (
            <TableRow key={user.user_id}>
              <TableCell  style={{ padding: '8px' }}>{`${user.first_name} ${user.last_name}`}</TableCell>
              <TableCell  style={{ padding: '8px' }}>{user.user_id}</TableCell>
              <TableCell  style={{ padding: '8px' }}>{user.location || 'Not Available'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </div>
    </Paper>
  );
}

export default AdminLocation;
