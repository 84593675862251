import React, { useState } from 'react';
import { TextField, Button, Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../assests/logo.png';

// Function to retrieve user ID from local storage
const getUserIdFromLocalStorage = () => {
  const userId = localStorage.getItem('userId');
  return userId;
};

const PasswordReset = () => {
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate(); // Get the navigate function

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async () => {
    setError('');
    setSuccessMessage('');

    try {
      const userId = getUserIdFromLocalStorage(); // Retrieve user ID from local storage
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/reset-password/${userId}/`, {
        otp,
        new_password: newPassword,
        confirm_password: confirmPassword
      });

      if (response.status === 200) {
        setSuccessMessage('Password reset successful.');
        navigate('/login'); // Redirect to the login page after successful password reset
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Please enter the correct OTP and ensure both passwords match.');
      } else {
        setError('An error occurred. Please try again later.');
      }
    }
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" padding="40px">

      <Typography variant="h4" gutterBottom align="left" style={{ marginBottom: '20px' }}>
        {/* Displaying your logo */}
        <img src={logo} alt="Logo" style={{ maxWidth: '100px', height: 'auto' }} />
      </Typography>

      <TextField
        label="OTP"
        variant="outlined"
        value={otp}
        onChange={handleOtpChange}
        style={{ marginBottom: '20px', width: '200px' }} // Set width to 200px
      />
      <TextField
        label="New Password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'} // Toggle password visibility
        value={newPassword}
        onChange={handleNewPasswordChange}
        style={{ marginBottom: '20px', width: '200px' }} // Set width to 200px
        InputProps={{ // Add a toggle view button for password field
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        label="Confirm Password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'} // Toggle password visibility
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        style={{ marginBottom: '20px', width: '200px' }} // Set width to 200px
        InputProps={{ // Add a toggle view button for password field
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
      {error && <p style={{ color: 'red', width: '100%', textAlign: 'center' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green', width: '100%', textAlign: 'center' }}>{successMessage}</p>}
    </Box>
  );
};

export default PasswordReset;
