import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Checkbox, FormControlLabel, Box, CircularProgress } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assests/logo.png';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Added loading state
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    setError('');
    setLoading(true); // Set loading state to true
  
    try {
      // Use the environment variable for the base URL
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      
      // Construct the request URL
      const requestUrl = `${baseUrl}api/users/login/`;
  
      // Log the request URL
      console.log('Request URL:', requestUrl);
  
      const response = await axios.post(requestUrl, {
        email,
        password,
      });
  
      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem('token', token);
        navigate('/Home');
      } else {
        setError('Invalid credentials or network error. Please try again.');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const data = error.response.data;
        if (data.email && data.password) {
          setError(`${data.email[0]} ${data.password[0]}`);
        } else if (data.email) {
          setError(data.email[0]);
        } else if (data.password) {
          setError(data.password[0]);
        } else {
          setError('An error occurred. Please try again later.');
        }
      } else {
        setError('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false); // Set loading state to false
    }
  };
  
  return (
    <Box mt={4} mx="auto" p={3} border={1} borderRadius={5} maxWidth="400px" marginTop="19px">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" gutterBottom align="left" style={{ marginBottom: '20px' }}>
          <img src={logo} alt="Logo" style={{ maxWidth: '100px', height: 'auto' }} />
        </Typography>
        <Typography variant="body2">
          <NavLink to="/mailScreen">Forgot Password?</NavLink> 
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="username"
            label="Email"
            variant="outlined"
            placeholder="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!error}
            helperText={error.includes('email') ? error : ''}
            disabled={loading} // Disable input field while loading
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!error}
            helperText={error.includes('password') ? error : ''}
            disabled={loading} // Disable input field while loading
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={togglePasswordVisibility}
                checked={showPassword}
                color="primary"
                disabled={loading} // Disable checkbox while loading
              />
            }
            label={showPassword ? 'Hide Password' : 'Show Password'}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel control={<Checkbox />} label="Keep me signed in" />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            disabled={loading} // Disable button while loading
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
          </Button>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography color="error" align="center">
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Login;
