import React from 'react';
import Search from "./Search";


const Home = () => {
  return (
    <div>
      <Search/>
    </div>
  );
};

export default Home;
