import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, Button, Modal, TextField, Typography, IconButton, MenuItem, Select, FormControl, InputLabel,Tab,Tabs } from '@mui/material';
import axios from 'axios';
import Search from './Search';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
const AdminSubscribers = () => {
    const [subscribers, setSubscribers] = useState([]);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [filteredSubscribers, setFilteredSubscribers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({
      video: '',
      channel: '',
      subscriber_id: '',
      subscriber_watched: '',
      subscriber_liked: '',
      subscriber_disliked: '',
      subscriber_comments: '',
      date_last_updated: ''
    });
    const [openModal, setOpenModal] = useState(false);
    const [newSubscriber, setNewSubscriber] = useState({
      video: '',
      channel: '',
      subscriber_id: '',
      subscriber_watched: '',
      subscriber_liked: '',
      subscriber_disliked: '',
      subscriber_comments: '',
      date_last_updated: '',
      location: ''
    });
    const [editingSubscriber, setEditingSubscriber] = useState(null);
    const [editSubscriberDetails, setEditSubscriberDetails] = useState({
      video: '',
      channel: '',
      subscriber_id: '',
      subscriber_watched: '',
      subscriber_liked: '',
      subscriber_disliked: '',
      subscriber_comments: '',
      date_last_updated: '',
      location: ''
    });
    const [editModalOpen, setEditModalOpen] = useState(false);
  
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);

    const locationOptions = ['Mumbai', 'Thanjavur', 'Indore'];
  
    const fetchSubscribers = async () => {
      try {
        setLoading(true); // Set loading state to true before the fetch starts
        const token = localStorage.getItem('token');
        
        if (!token) {
          throw new Error('Token not found. Please log in again.');
        }
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/video-interactions/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        
        // Assuming response.data.results is the array you want to use for mapping
        const fetchedSubscribers = response.data.results;
        
        // Update the state with the fetched data
        setSubscribers(fetchedSubscribers);
        setFilteredSubscribers(fetchedSubscribers); // Initialize filteredSubscribers with fetched data
    
      } catch (error) {
        console.error('Error fetching subscribers:', error);
        // Optionally, provide user feedback here
        alert('Failed to fetch subscribers. Please try again later.');
      } finally {
        setLoading(false); // Set loading state to false after the fetch completes
      }
    };
    
    
    
    useEffect(() => {
      fetchSubscribers();
    }, []);
    
    console.log('Subscribers:', subscribers); // Assuming subscribers is your state holding subscriber data
    console.log('Filtered Subscribers:', filteredSubscribers); // Assuming filteredSubscribers is your state for filtered subscriber data
    
  
    const handleSearch = (searchParams) => {
      setSearchParams(searchParams);
    
      let filtered = subscribers;
    
      Object.entries(searchParams).forEach(([key, value]) => {
        if (value) {
          if (key === 'subscriberName') {
            const normalizedSubscriberName = value.trim().toLowerCase();
            filtered = filtered.filter(subscriber =>
              subscriber.name && subscriber.name.toLowerCase().includes(normalizedSubscriberName)
            );
          } else if (key === 'subscriberEmail') {
            const normalizedSubscriberEmail = value.trim().toLowerCase();
            filtered = filtered.filter(subscriber =>
              subscriber.email && subscriber.email.toLowerCase().includes(normalizedSubscriberEmail)
            );
          }
        }
      });
    
      setFilteredSubscribers(filtered);
    };
    
    
  
    const handleCreateSubscriber = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/video-interactions/`, newSubscriber);
        setOpenModal(false);
        fetchSubscribers();
      } catch (error) {
        console.error('Error creating subscriber:', error.response.data);
      }
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNewSubscriber(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleEditSubscriber = (subscriber) => {
      setEditingSubscriber(subscriber);
      setEditSubscriberDetails({ ...subscriber });
      setEditModalOpen(true);
    };
  
    const handleUpdateSubscriber = async () => {
      try {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/video-interactions/${editingSubscriber.id}/`, editSubscriberDetails);
        setEditModalOpen(false);
        fetchSubscribers();
      } catch (error) {
        console.error('Error updating subscriber:', error.response.data);
      }
    };
  
    const handleEditInputChange = (e) => {
      const { name, value } = e.target;
      setEditSubscriberDetails(prevState => ({
        ...prevState,
        [name]: value
      }));
    };

    // Helper function to format date-time string
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString(); // Adjust as needed for the desired format
    };
  
    const handleInfo = (subscriber) => {
      setSelectedSubscriber(subscriber);
      setOpenInfoModal(true);
    };


  
    const handleCloseInfoModal = () => {
      setOpenInfoModal(false);
    };

    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
    const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="create-subscriber-modal-title"
        aria-describedby="create-subscriber-modal-description"
      >
        <Box sx={{ 
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
}}>
 <IconButton onClick={() => setOpenModal(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5">Create Subscriber</Typography>
          {/* TextFields for input */}
          <TextField
            label="Video"
            variant="outlined"
            name="video"
            value={newSubscriber.video}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Channel"
            variant="outlined"
            name="channel"
            value={newSubscriber.channel}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber ID"
            variant="outlined"
            name="subscriber_id"
            value={newSubscriber.subscriber_id}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber Watched"
            variant="outlined"
            name="subscriber_watched"
            value={newSubscriber.subscriber_watched}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber Liked"
            variant="outlined"
            name="subscriber_liked"
            value={newSubscriber.subscriber_liked}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber Disliked"
            variant="outlined"
            name="subscriber_disliked"
            value={newSubscriber.subscriber_disliked}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber Comments"
            variant="outlined"
            name="subscriber_comments"
            value={newSubscriber.subscriber_comments}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Location</InputLabel>
            <Select
              value={newSubscriber.location}
              onChange={handleInputChange}
              name="location"
            >
              {locationOptions.map((location, index) => (
                <MenuItem key={index} value={location}>{location}</MenuItem>
              ))}
            </Select>
          </FormControl>
         <TextField
  label="Date Last Updated"
  variant="outlined"
  type="date"
  name="date_last_updated"
  value={newSubscriber.date_last_updated}
  onChange={handleInputChange}
  fullWidth
  margin="normal"
/>

          <Button onClick={handleCreateSubscriber} variant="contained">Create</Button>
        </Box>
      </Modal>
      <Box>
     
        <Search onSearch={handleSearch} />
        <Box display="flex" justifyContent="flex-end">
        <Button onClick={handleOpenModal} variant="contained"  style={{ marginBottom: '02px' }}>Create Subscriber</Button>
      </Box>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <Table>
        <TableHead>
        <TableRow sx={{ bgcolor: '#e0e0e0', position: 'sticky', top: 0, zIndex: 1}}>
    <TableCell><b>Subscriber Name</b></TableCell>
    <TableCell><b> Email</b></TableCell>
    <TableCell><b> Watched count </b></TableCell>
    <TableCell><b> Liked</b></TableCell>
    <TableCell><b> Disliked</b></TableCell>
    <TableCell><b> Location</b></TableCell>
    <TableCell><b> Comments</b></TableCell>
    <TableCell><b>Date Joined</b></TableCell>
    <TableCell><b>Action</b></TableCell>
  </TableRow>
</TableHead>

          <TableBody>
            {filteredSubscribers.map((subscriber) => (
              <TableRow key={subscriber.id}>
                {/* Table cells for each subscriber field */}
                <TableCell  style={{ padding: '0.01px' }}>{subscriber.name}</TableCell>
                <TableCell  style={{ padding: '0.01px' }}>{subscriber.email}</TableCell>
                <TableCell style={{ padding: '0.01px' }}>{subscriber.total_video_view}</TableCell> {/* Display total video views */}
                <TableCell  style={{ padding: '0.01px' }}>{subscriber.subscriber_liked ? 'Yes' : 'No'}</TableCell>
                <TableCell  style={{ padding: '0.01px' }}>{subscriber.subscriber_disliked ? 'Yes' : 'No'}</TableCell>
                <TableCell  style={{ padding: '0.01px' }}>{subscriber.location}</TableCell>
                <TableCell  style={{ padding: '0.01px' }}>{subscriber.subscriber_comments}</TableCell>
                <TableCell  style={{ padding: '0.01px' }}>{formatDate(subscriber.date_joined)}</TableCell>
                <TableCell style={{ padding: '0.01px' }}>
                  <IconButton onClick={() => handleEditSubscriber(subscriber)}>
                  <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleInfo(subscriber)} aria-label="info">
                      <InfoIcon />
                    </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>
      </Box>
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="edit-subscriber-modal-title"
        aria-describedby="edit-subscriber-modal-description"
      >
        <Box sx={{ 
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
}}>
         <IconButton onClick={() => setOpenModal(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          {/* Modal Content for editing subscriber */}
          <Typography variant="h5">Edit Subscriber</Typography>
          <TextField
            label="Video"
            variant="outlined"
            name="video"
            value={editSubscriberDetails.video}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Channel"
            variant="outlined"
            name="channel"
            value={editSubscriberDetails.channel}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber ID"
            variant="outlined"
            name="subscriber_id"
            value={editSubscriberDetails.subscriber_id}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber Watched"
            variant="outlined"
            name="subscriber_watched"
            value={editSubscriberDetails.subscriber_watched}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber Liked"
            variant="outlined"
            name="subscriber_liked"
            value={editSubscriberDetails.subscriber_liked}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber Disliked"
            variant="outlined"
            name="subscriber_disliked"
            value={editSubscriberDetails.subscriber_disliked}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Subscriber Comments"
            variant="outlined"
            name="subscriber_comments"
            value={editSubscriberDetails.subscriber_comments}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          {/* <TextField
  variant="outlined"
  type="date"
  name="date_last_updated"
  value={newSubscriber.date_last_updated}
  onChange={handleInputChange}
  fullWidth
  margin="normal"
/> */}

<Box display="flex" justifyContent="flex-end" marginTop="10px">
  <Button onClick={handleUpdateSubscriber} variant="contained">Update</Button>
</Box>

        </Box>
      </Modal>
      <Modal
        open={openInfoModal}
        onClose={handleCloseInfoModal}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            height: '80vh',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
          }}
        >
          <IconButton onClick={handleCloseInfoModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
  <Tabs 
    value={selectedTab} 
    onChange={handleTabChange} 
    sx={{ 
      width: '100%', 
      maxWidth: 600,
      '& .MuiTabs-indicator': {
        display: 'none'
      }
    }}
  >
    <Tab label="Details" sx={{ flex: 1 }} />
    <Tab label="Documents" sx={{ flex: 1 }} />
    <Tab label="History" sx={{ flex: 1 }} />
    <Tab label="Watched" sx={{ flex: 1 }} />
  </Tabs>
</Box>





          <Box hidden={selectedTab !== 0}>
            <Table style={{ marginTop: '5px' }}>
              <TableBody>
                <TableRow>
                  <TableCell>Subscriber ID:</TableCell>
                  <TableCell>{selectedSubscriber?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Subscriber Name:</TableCell>
                  <TableCell>{selectedSubscriber?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Subscriber Email</TableCell>
                  <TableCell>{selectedSubscriber?.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Subscriber Comments:</TableCell>
                  <TableCell>{selectedSubscriber?.subscriber_comments}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date Joined:</TableCell>
                  <TableCell>{formatDate(selectedSubscriber?.date_joined)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Video Views:</TableCell>
                  <TableCell>{selectedSubscriber?.total_video_view}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box hidden={selectedTab !== 1}>
            {/* Contents for the "Documents" tab */}
          </Box>
          <Box hidden={selectedTab !== 2}>
          <Table>
  <TableHead>
    <TableRow>
      <TableCell style={{ fontWeight: 'bold' }}>Category</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Date/Time</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    <TableRow>
      <TableCell>Joined</TableCell>
      <TableCell>
      
      </TableCell>
      <TableCell>{formatDate(selectedSubscriber?.date_joined)}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Subscribed channel</TableCell>
      <TableCell>{selectedSubscriber?.subscribed_channel_name}</TableCell>
      <TableCell>{formatDate(selectedSubscriber?.subscribed_channel_time)}</TableCell>
    </TableRow>
   
    <TableRow>
      <TableCell>Unsubscribed channel</TableCell>
      <TableCell>{selectedSubscriber?.unsubscribed_channel_name}</TableCell>
      <TableCell></TableCell> {/* No specific time provided for unsubscribed channel */}
    </TableRow>
    <TableRow>
      <TableCell>Liked video</TableCell>
      <TableCell>{selectedSubscriber?.liked_video_name}</TableCell>
      <TableCell></TableCell> {/* No specific time provided for liked video */}
    </TableRow>
    <TableRow>
      <TableCell>Disliked video</TableCell>
      <TableCell>{selectedSubscriber?.disliked_video_name}</TableCell>
      <TableCell></TableCell> {/* No specific time provided for disliked video */}
    </TableRow>
    <TableRow>
      <TableCell>Comments on video</TableCell>
      <TableCell>{selectedSubscriber?.comments_video_name}</TableCell>
      <TableCell></TableCell> {/* No specific time provided for comments on video */}
    </TableRow>
  </TableBody>
</Table>

          </Box>
          <Box hidden={selectedTab !== 3}>
          <Typography variant="h6" style={{ display: 'flex', justifyContent: 'center',fontweight: 'bolder' }}>
  Watched Videos
</Typography>

  {selectedSubscriber?.watched_video && selectedSubscriber.watched_video.split(',').map((videoId, index) => (
    <>
      <TableRow key={index}>
        <TableCell>{videoId}</TableCell>
        <TableCell></TableCell> {/* No specific time provided for watched video */}
      </TableRow>
    </>
  ))}
</Box>



        </Box>
        
      </Modal>

    </div>
  );
};

export default AdminSubscribers;
