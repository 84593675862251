import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, Button, Modal, TextField, Typography, IconButton, MenuItem, Select, FormControl, InputLabel,Grid } from '@mui/material';
import axios from 'axios';
import Search from './Search';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const AdminArtists = () => {
  const [artists, setArtists] = useState([]);
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [searchParams, setSearchParams] = useState({
    firstName: '',
    lastName: '',
    language: '',
    phoneNumber: '',
    email: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [newArtist, setNewArtist] = useState({
    first_name: '',
    last_name: '',
    language: '',
    title: '',
    phone: '',
    email: '',
    location: ''
  });
  const [editingArtist, setEditingArtist] = useState(null);
  const [editArtistDetails, setEditArtistDetails] = useState({
    first_name: '',
    last_name: '',
    language: '',
    title:'',
    phone: '',
    email: '',
    location: ''
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const locationOptions = ['Mumbai', 'Thanjavur', 'Indore'];

  const fetchArtists = async () => {
    try {
      setLoading(true); // Set loading state to true before the fetch starts
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Token not found. Please log in again.');
      }
      
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/artists/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      
      // Assuming response.data.results is the array you want to use for mapping
      const fetchedArtists = response.data.results;
      
      // Update the state with the fetched data
      setArtists(fetchedArtists);
      setFilteredArtists(fetchedArtists); // Initialize filteredArtists with fetched data
  
    } catch (error) {
      console.error('Error fetching artists:', error);
      // Optionally, provide user feedback here
      alert('Failed to fetch artists. Please try again later.');
    } finally {
      setLoading(false); // Set loading state to false after the fetch completes
    }
  };
  

  useEffect(() => {
    fetchArtists();
  }, []);

  useEffect(() => {
    const isAnySearchParamNotEmpty = Object.values(searchParams).some(param => param.trim() !== '');
    if (!isAnySearchParamNotEmpty) {
      setFilteredArtists(artists);
    }
  }, [searchParams, artists]);

  const handleSearch = (searchParams) => {
    setSearchParams(searchParams);

    const { firstName, lastName, language, phoneNumber, email } = searchParams;
    let filtered = artists;

    if (firstName) {
      filtered = filtered.filter(artist => artist.first_name.toLowerCase().startsWith(firstName.toLowerCase()));
    }

    if (lastName) {
      filtered = filtered.filter(artist => artist.last_name.toLowerCase().startsWith(lastName.toLowerCase()));
    }

    if (language) {
      filtered = filtered.filter(artist => artist.language.toLowerCase().startsWith(language.toLowerCase()));
    }

    if (phoneNumber) {
      filtered = filtered.filter(artist => artist.phone.startsWith(phoneNumber));
    }

    if (email) {
      filtered = filtered.filter(artist => artist.email.toLowerCase().startsWith(email.toLowerCase()));
    }

    setFilteredArtists(filtered);
  };

  const handleCreateArtist = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/artists/`, newArtist);
      setOpenModal(false);
      fetchArtists();

      setNewArtist({
        first_name: '',
        last_name: '',
        language: '',
        title: '',
        phone: '',
        email: '',
        location: ''
      });
    } catch (error) {
      console.error('Error creating artist:', error.response.data);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewArtist(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleEditArtist = (artist) => {
    setEditingArtist(artist);
    setEditArtistDetails({ ...artist });
    setEditModalOpen(true);
  };

  const handleUpdateArtist = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/artists/${editingArtist.id}/`, editArtistDetails);
      setEditModalOpen(false);
      fetchArtists();
    } catch (error) {
      console.error('Error updating artist:', error.response.data);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditArtistDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const [openInfoModal, setOpenInfoModal] = useState(false);
    const [selectedArtist, setSelectedArtist] = useState(null);
    const handleInfo = (artist) => {
        setSelectedArtist(artist);
        setOpenInfoModal(true);
      };
  
  
    
      const handleCloseInfoModal = () => {
        setOpenInfoModal(false);
      };
      


  return (
    <div>
     
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="create-artist-modal-title"
        aria-describedby="create-artist-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height:'80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <IconButton onClick={() => setOpenModal(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <h2 id="create-artist-modal-title">Create Artist</h2>
          <TextField
            label="First Name"
            variant="outlined"
            name="first_name"
            value={newArtist.first_name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            variant="outlined"
            name="last_name"
            value={newArtist.last_name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Language"
            variant="outlined"
            name="language"
            value={newArtist.language}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="title"
            variant="outlined"
            name="title"
            value={newArtist.title}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone"
            variant="outlined"
            name="phone"
            value={newArtist.phone}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            value={newArtist.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Location</InputLabel>
            <Select
              value={newArtist.location}
              onChange={handleInputChange}
              name="location"
            >
              {locationOptions.map((location, index) => (
                <MenuItem key={index} value={location}>{location}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={handleCreateArtist} variant="contained">Create</Button>
          </Box>
        </Box>
      </Modal>
      <Box >
        <Search onSearch={handleSearch} />
        <Grid container justifyContent="flex-end" marginTop="1px" marginRight="5px">
        <Grid item>
          <Button onClick={() => setOpenModal(true)} variant="contained"   style={{ marginBottom: '02px' }}>Create Artist</Button>
        </Grid>
      </Grid>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <Table>
  <TableHead>
    <TableRow sx={{ bgcolor: '#e0e0e0' }}>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Serial No</TableCell>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>First Name</TableCell>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Last Name</TableCell>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Artist title</TableCell>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Language</TableCell>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Location</TableCell>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Phone</TableCell>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Email</TableCell>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Date Last Updated</TableCell>
      <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Actions</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {filteredArtists.map((artist, index) => (
      <TableRow key={artist.id}>
        <TableCell style={{ padding: '0.1px' }}>{index + 1}</TableCell>
        <TableCell style={{ padding: '0.1px' }}>{artist.first_name}</TableCell>
        <TableCell style={{ padding: '0.1px' }}>{artist.last_name}</TableCell>
        <TableCell style={{ padding: '0.1px' }}>{artist.title}</TableCell>
        <TableCell style={{ padding: '0.1px' }}>{artist.language}</TableCell>
        <TableCell style={{ padding: '0.1px' }}>{artist.location}</TableCell>
        <TableCell style={{ padding: '0.1px' }}>{artist.phone}</TableCell>
        <TableCell style={{ padding: '0.1px' }}>{artist.email}</TableCell>
        <TableCell style={{ padding: '0.1px' }}>{artist.last_updated}</TableCell>
        <TableCell style={{ padding: '0.1px' }}>
          <IconButton onClick={() => handleEditArtist(artist)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleInfo(artist)} aria-label="info">
                      <InfoIcon />
                    </IconButton>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>


        </div>
      </Box>
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="edit-artist-modal-title"
        aria-describedby="edit-artist-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height:'80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <IconButton onClick={() => setEditModalOpen(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <h2 id="edit-artist-modal-title">Edit Artist</h2>
          <TextField
            label="First Name"
            variant="outlined"
            name="first_name"
            value={editArtistDetails.first_name}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            variant="outlined"
            name="last_name"
            value={editArtistDetails.last_name}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Language"
            variant="outlined"
            name="language"
            value={editArtistDetails.language}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
           <TextField
            label="title"
            variant="outlined"
            name="title"
            value={editArtistDetails.title}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone"
            variant="outlined"
            name="phone"
            value={editArtistDetails.phone}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            value={editArtistDetails.email}
            onChange={handleEditInputChange}
            fullWidth
            margin="normal"
          />
           <FormControl fullWidth margin="normal">
            <InputLabel>Location</InputLabel>
            <Select
              value={editArtistDetails.location}
              onChange={handleInputChange}
              name="location"
            >
              {locationOptions.map((location, index) => (
                <MenuItem key={index} value={location}>{location}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginRight: '10px' }}>
            <Button onClick={handleUpdateArtist} variant="contained" color="primary">Update</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
  open={openInfoModal}
  onClose={handleCloseInfoModal}
  aria-labelledby="artist-info-modal-title"
  aria-describedby="artist-info-modal-description"
>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
  }}>
    <IconButton onClick={handleCloseInfoModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
      <CloseIcon />
    </IconButton>
    {selectedArtist && (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button> Details</Button>
          <Button> Documents</Button>
          <Button> History</Button>
        </div>

        <Table style={{ marginTop: '5px' }}>
          <TableBody>
            <TableRow>
              <TableCell>First Name:</TableCell>
              <TableCell>{selectedArtist.first_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Name:</TableCell>
              <TableCell>{selectedArtist.last_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Language:</TableCell>
              <TableCell>{selectedArtist.language}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone:</TableCell>
              <TableCell>{selectedArtist.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email:</TableCell>
              <TableCell>{selectedArtist.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date Last Updated:</TableCell>
              <TableCell>{selectedArtist.last_updated}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )}
  </Box>
</Modal>

    </div>
  );
};

export default AdminArtists;
