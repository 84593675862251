import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box } from '@mui/material';
import AdminSearch from './admin/AdminSearch';
import axios from 'axios';

const LoginHistory = () => {
  const [loginHistory, setLoginHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredLoginHistory, setFilteredLoginHistory] = useState([]);
  const [searchParams, setSearchParams] = useState({
    firstName: '',
    lastName: '',
    userId: '',
    telephoneNumber: '',
    email: '',
    location: '',
    language: '',
  });

  useEffect(() => {
    fetchLoginHistory(); // Fetch initial login history
  }, []);

  const fetchLoginHistory = async () => {
    try {
      setLoading(true); // Set loading state to true before the fetch starts
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Token not found. Please log in again.');
      }
      
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/user-login/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      
      // Assuming response.data.results is the array you want to use for mapping
      const fetchedLoginHistory = response.data.results;
      
      // Update the state with the fetched data
      setLoginHistory(fetchedLoginHistory);
      setFilteredLoginHistory(fetchedLoginHistory); // Initialize filteredLoginHistory with fetched data
  
    } catch (error) {
      console.error('Error fetching login history:', error);
      // Optionally, provide user feedback here
      alert('Failed to fetch login history. Please try again later.');
    } finally {
      setLoading(false); // Set loading state to false after the fetch completes
    }
  };

  useEffect(() => {
    // If all search parameters are empty, load all data
    const isAllSearchParamsEmpty = Object.values(searchParams).every(param => param.trim() === '');
    if (isAllSearchParamsEmpty) {
      setFilteredLoginHistory(loginHistory);
    }
  }, [searchParams, loginHistory]);

  const handleSearch = (searchParams) => {
    setSearchParams(searchParams);

    const { firstName, lastName, userId, telephoneNumber, email, location } = searchParams;
    let filtered = loginHistory;

    if (firstName) {
      filtered = filtered.filter(login => login.user_email.toLowerCase().startsWith(firstName.toLowerCase()));
    }

    if (lastName) {
      filtered = filtered.filter(login => login.user_email.toLowerCase().startsWith(lastName.toLowerCase()));
    }

    if (userId) {
      filtered = filtered.filter(login => login.user_id.toString().startsWith(userId));
    }

    if (telephoneNumber) {
      filtered = filtered.filter(login => login.login_ip.startsWith(telephoneNumber));
    }

    if (email) {
      filtered = filtered.filter(login => login.user_email.toLowerCase().startsWith(email.toLowerCase()));
    }

    if (location) {
      filtered = filtered.filter(login => login.location && login.location.toLowerCase().startsWith(location.toLowerCase()));
    }

    setFilteredLoginHistory(filtered);
  };

  return (
    <Box marginTop="10px">
      <AdminSearch onSearch={handleSearch} />
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        
      <Table >
        <TableHead>
          <TableRow sx={{ bgcolor: '#e0e0e0' }}>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>User Email</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Login Date Time</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Login State</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', py: 1 }}>Login IP</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredLoginHistory.map((login, index) => (
            <TableRow key={index}>
              <TableCell style={{ padding: '0.2px' }}>{login.user_email}</TableCell>
              <TableCell style={{ padding: '0.2px' }}>
    {new Date(login.login_date_time).toLocaleString()}
  </TableCell>
              <TableCell style={{ padding: '0.2px' }}>{login.login_successful ? 'Successful' : 'Failed'}</TableCell>
              <TableCell style={{ padding: '0.2px' }}>{login.login_ip}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </div>
    </Box>
  );
};

export default LoginHistory;
