import React, { useState } from 'react';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../assests/logo.png';

const MailScreen = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    setError('');
    setSuccessMessage('');
    setLoading(true);

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/request-password-reset/`, {
        email: email
      });

      if (response.status === 200) {
        const responseData = response.data;
        const userId = responseData.user_id;

        // Store user ID in local storage
        localStorage.setItem('userId', userId);

        setSuccessMessage('Password reset request sent successfully. Please check your inbox.');
        navigate('/passwordReset');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('User with this email address not found. Please enter a valid email.');
      } else {
        setError('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h4" gutterBottom align="left" style={{ marginBottom: '20px' }}>
        {/* Displaying your logo */}
        <img src={logo} alt="Logo" style={{ maxWidth: '100px', height: 'auto' }} />
      </Typography>
      <TextField
        label="Email"
        variant="outlined"
        value={email}
        onChange={handleEmailChange}
        style={{ marginBottom: '20px' }}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
      </Button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
    </Box>
  );
};

export default MailScreen;
