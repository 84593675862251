import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';

const AdminSearch = ({ onSearch }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userId, setUserId] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [language, setLanguage] = useState('');

  const handleReset = () => {
    setFirstName('');
    setLastName('');
    setUserId('');
    setTelephoneNumber('');
    setEmail('');
    setLocation('');
    setLanguage('');
    onSearch({
      firstName: '',
      lastName: '',
      userId: '',
      telephoneNumber: '',
      email: '',
      location: '',
      language: ''
    });
  };

  const handleInputChange = (field, value) => {
    switch (field) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'userId':
        setUserId(value);
        break;
      case 'telephoneNumber':
        setTelephoneNumber(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'location':
        setLocation(value);
        break;
      case 'language':
        setLanguage(value);
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    const searchParams = {
      firstName,
      lastName,
      userId,
      telephoneNumber,
      email,
      location,
      language
    };
    onSearch(searchParams);
  };

  return (
    <Box position="sticky" top={0} bgcolor="white" zIndex={1000}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:"3px"}}>
        <TextField
          variant="outlined"
          size="small"
          label="First Name"
          value={firstName}
          onChange={(e) => handleInputChange('firstName', e.target.value)}
          InputProps={{ style: { fontSize: '16px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Last Name"
          value={lastName}
          onChange={(e) => handleInputChange('lastName', e.target.value)}
          InputProps={{ style: { fontSize: '16px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="User ID"
          value={userId}
          onChange={(e) => handleInputChange('userId', e.target.value)}
          InputProps={{ style: { fontSize: '16px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Telephone Number"
          value={telephoneNumber}
          onChange={(e) => handleInputChange('telephoneNumber', e.target.value)}
          InputProps={{ style: { fontSize: '16px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Email"
          value={email}
          onChange={(e) => handleInputChange('email', e.target.value)}
          InputProps={{ style: { fontSize: '16px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Location"
          value={location}
          onChange={(e) => handleInputChange('location', e.target.value)}
          InputProps={{ style: { fontSize: '16px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Language"
          value={language}
          onChange={(e) => handleInputChange('language', e.target.value)}
          InputProps={{ style: { fontSize: '16px', padding: '3px', borderColor: 'blue' } }}
        />
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Button variant="contained" color="primary" onClick={handleSearch} style={{ fontSize: '10px', padding: '6px', marginRight: '5px' }}>Search</Button>
        <Button variant="contained" color="secondary" onClick={handleReset} style={{ fontSize: '10px', padding: '6px' }}>Reset</Button>
      </div>
    </Box>
  );
};

export default AdminSearch;
