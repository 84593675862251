import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Search = ({ onSearch }) => {
  const [channel, setChannel] = useState('');
  const [videos, setVideos] = useState('');
  const [subscribers, setSubscribers] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [vendors, setVendors] = useState('');
  const [language, setLanguage] = useState('');



  const location = useLocation();
  const currentRoute = location.pathname;

  // Render the Search component only if not in the mailScreen route
  if (currentRoute === '/mailScreen') {
    return null;
  }

  
  const handleReset = () => {
    setChannel('');
    setVideos('');
    setSubscribers('');
    setTelephoneNumber('');
    setEmail('');
    setVendors('');
    setLanguage('');
    onSearch({
      channel: '',
      videos: '',
      subscribers: '',
      telephoneNumber: '',
      email: '',
      vendors: '',
      language: ''
    });
  };

  const handleInputChange = (field, value) => {
    switch (field) {
      case 'channel':
        setChannel(value);
        break;
      case 'videos':
        setVideos(value);
        break;
      case 'subscribers':
        setSubscribers(value);
        break;
      case 'telephoneNumber':
        setTelephoneNumber(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'vendors':
        setVendors(value);
        break;
      case 'language':
        setLanguage(value);
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    const searchParams = {
      channel,
      videoTitle: videos,
      subscriberName:subscribers,
      telephoneNumber,
      email,
      vendor_name:vendors,
      language
    };
    onSearch(searchParams);
  };

  

  return (
    <Box position="sticky" top={0} bgcolor="white" zIndex={1000}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:"3px"}}>
        <TextField
          variant="outlined"
          size="small"
          label="Channel"
          value={channel}
          onChange={(e) => handleInputChange('channel', e.target.value)}
          InputProps={{ style: { fontSize: '14px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Videos"
          value={videos}
          onChange={(e) => handleInputChange('videos', e.target.value)}
          InputProps={{ style: { fontSize: '14px', padding: '3px', borderColor: 'blue' } }}
          placeholder="Enter video title or ID"
        />
        <TextField
          variant="outlined"
          size="small"
          label="Subscribers"
          value={subscribers}
          onChange={(e) => handleInputChange('subscribers', e.target.value)}
          InputProps={{ style: { fontSize: '14px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Telephone Number"
          value={telephoneNumber}
          onChange={(e) => handleInputChange('telephoneNumber', e.target.value)}
          InputProps={{ style: { fontSize: '14px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Email"
          value={email}
          onChange={(e) => handleInputChange('email', e.target.value)}
          InputProps={{ style: { fontSize: '14px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Vendors"
          value={vendors}
          onChange={(e) => handleInputChange('vendors', e.target.value)}
          InputProps={{ style: { fontSize: '14px', padding: '3px', borderColor: 'blue' } }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Language"
          value={language}
          onChange={(e) => handleInputChange('language', e.target.value)}
          InputProps={{ style: { fontSize: '14px', padding: '3px', borderColor: 'blue' } }}
        />
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Button variant="contained" color="primary" onClick={handleSearch} style={{ fontSize: '10px', padding: '6px', marginRight: '5px' }}>Search</Button>
        <Button variant="contained" color="secondary" onClick={handleReset} style={{ fontSize: '10px', padding: '6px' }}>Reset</Button>
      </div>
    </Box>
  );
};

export default Search;
