import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, Button, Modal, TextField, Grid, IconButton, CircularProgress,Typography } from '@mui/material';
import axios from 'axios';
import Search from './Search';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';


const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newVideo, setNewVideo] = useState({
    channel_id: '',
    video_id: '',
    video_views: 0,
    video_likes: 0,
    video_dislikes: 0,
    video_comments: 0,
    video_upload_date: '',
    video_length: 0,
    sponsored: false,
    date_last_updated: ''
  });
  const [selectedChannelId, setSelectedChannelId] = useState('');
  const [editingVideo, setEditingVideo] = useState(null);
  const [editVideoDetails, setEditVideoDetails] = useState({
    channel_id: '',
    video_views: 0,
    video_likes: 0,
    video_dislikes: 0,
    video_comments: 0,
    video_upload_date: '',
    video_length: 0,
    sponsored: false,
    date_last_updated: ''
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('Details'); // Added selectedTab state
  const [videoHistory, setVideoHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState(null);

  useEffect(() => {
    fetchVideos();
  },[]);

  const handleMenuOpen = () => {
    setOpenModal(true);
  };

  const handleInfo = (video) => {
    setSelectedVideo(video);
    setOpenInfoModal(true);
    handleTabChange('Details');
    if (video) {
      fetchVideoHistory(video.video_id); // Pass the video ID to fetch history
    }
  };

  const fetchVideos = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
  
      // Assuming response.data.results is the array you want to use for mapping
      const fetchedVideos = response.data.results;
  
      setVideos(fetchedVideos); // Assuming videos should be an array of results
      setFilteredVideos(fetchedVideos); // Initialize filteredVideos with fetched data
      setNextPageUrl(response.data.next);
  
    } catch (error) {
      console.error('Error Fetching videos:', error);
    } finally {
      setLoading(false);
    }
  };
  

  console.log('Videos:', videos);
  console.log('Filtered Videos:', filteredVideos);

  const handleSearch = (searchParams) => {
    const { videoTitle, channel } = searchParams;
    let filtered = [...videos];
  
    if (videoTitle) {
      const normalizedVideoTitle = videoTitle.trim().toLowerCase();
      filtered = filtered.filter(video =>
        video.video_title && video.video_title.toLowerCase().includes(normalizedVideoTitle)
      );
    }
  
    if (channel) {
      const normalizedChannelName = channel.trim().toLowerCase();
      filtered = filtered.filter(video =>
        video.channel_name && video.channel_name.toLowerCase().includes(normalizedChannelName)
      );
    }
  
    setFilteredVideos(filtered);
  };
  
  
  
  
  

  // const handleSearch = (searchParams) => {
  //   const { channel } = searchParams;
  //   let filtered = [...channels];
    
  //   if (typeof channel === 'string') {
  //     // Ensure channel is not empty before filtering
  //     const normalizedChannelName = channel.trim().toLowerCase(); // Normalize for case-insensitive comparison
  //     if (normalizedChannelName) {
  //       filtered = filtered.filter(channel =>
  //         channel.channel_name.toLowerCase().includes(normalizedChannelName)
  //       );
  //     }
  //   }
    
  //   setFilteredChannels(filtered);
  // };

  
  

  const handleCreateVideo = async () => {
    try {
      const token = localStorage.getItem('token');
      const data = {
        channel: selectedChannelId,
        video_id: newVideo.video_id,
        video_views: newVideo.video_views,
        video_likes: newVideo.video_likes,
        video_dislikes: newVideo.video_dislikes,
        video_comments: newVideo.video_comments,
        video_upload_date: newVideo.video_upload_date,
        video_length: newVideo.video_length,
        sponsored: newVideo.sponsored,
        date_last_updated: newVideo.date_last_updated
      };
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/videos/`, data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      setOpenModal(false);
      fetchVideos();
      setNewVideo({
        channel_id: '',
        video_id: '',
        video_views: 0,
        video_likes: 0,
        video_dislikes: 0,
        video_comments: 0,
        video_upload_date: '',
        video_length: 0,
        sponsored: false,
        date_last_updated: ''
      });
    } catch (error) {
      console.error('Error creating video:', error.response.data);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'channel_id') {
      setSelectedChannelId(value);
    }
    setNewVideo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCloseModal = () => {
    setNewVideo({
      channel_id: '',
      video_id: '',
      video_views: 0,
      video_likes: 0,
      video_dislikes: 0,
      video_comments: 0,
      video_upload_date: '',
      video_length: 0,
      sponsored: false,
      date_last_updated: ''
    });
    setOpenModal(false);
  };

  const handleEditVideo = (video) => {
    setEditingVideo(video);
    setEditVideoDetails({ ...video });
    setEditModalOpen(true);
  };

  const handleUpdateVideo = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/videos/${editingVideo.id}/`, editVideoDetails, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      setEditModalOpen(false);
      fetchVideos();
    } catch (error) {
      console.error('Error updating video:', error.response.data);
    }
  };

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditVideoDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const fetchVideoHistory = async (videoId) => {
    try {
      setLoadingHistory(true); // Set loading state to true
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos/history/${videoId}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      console.log(response.data);
      setVideoHistory(response.data);
    } catch (error) {
      console.error('Error fetching video history:', error);
    } finally {
      setLoadingHistory(false); // Set loading state to false regardless of success or failure
    }
  };
  
  
  const handleTabChange = (tab) => {
  if (tab === 'History' && selectedVideo) {
    fetchVideoHistory(selectedVideo.video_id); // Use video_id to fetch history
  }
  setSelectedTab(tab);
};


const handleNextPage = () => {
  if (nextPageUrl) {
    fetchVideos(nextPageUrl); // Fetch next page if there's a next page URL
  }
};

const handlePreviousPage = () => {
  // You can implement previous page functionality if needed
  // This depends on how you handle pagination in your backend
  console.log('Previous page functionality');
};

  return (
    <div>
      {loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="create-video-modal-title"
        aria-describedby="create-video-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: '80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <IconButton onClick={handleCloseModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <h2 id="create-video-modal-title">Create Video</h2>
          <TextField
            name="channel_id"
            label="Channel ID"
            value={newVideo.channel_id}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <TextField
            name="video_id"
            label="Video ID"
            value={newVideo.video_id}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <TextField
            name="video_views"
            label="Video Views"
            value={newVideo.video_views}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="video_likes"
            label="Video Likes"
            value={newVideo.video_likes}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="video_dislikes"
            label="Video Dislikes"
            value={newVideo.video_dislikes}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="video_comments"
            label="Video Comments"
            value={newVideo.video_comments}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="video_upload_date"
            type="date"
            value={newVideo.video_upload_date}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="video_length"
            label="Video Length"
            value={newVideo.video_length}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="sponsored"
            label="Sponsored"
            value={newVideo.sponsored}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="date_last_updated"
            type="date"
            value={newVideo.date_last_updated}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <Button onClick={handleCreateVideo} variant="contained" color="primary">Create Video</Button>
        </Box>
      </Modal>
      <Box>
        <Search onSearch={handleSearch} />
        <Grid container justifyContent="flex-end" marginTop="2px" marginRight="5px">
          <Grid item>
            <Button onClick={() => setOpenModal(true)} variant="contained" style={{ marginBottom: '02px' }}>Create Video</Button>
          </Grid>

          <Grid item>
            <Button onClick={handlePreviousPage} variant="contained" style={{ marginBottom: '02px', marginRight: '10px' }}>Previous</Button>
            <Button onClick={handleNextPage} variant="contained" style={{ marginBottom: '02px' }}>Next</Button>
          </Grid>
          
        </Grid>

        
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <Table>
            <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#e0e0e0' }}>
              <TableRow sx={{ bgcolor: '#e0e0e0' }}>
                <TableCell sx={{ fontWeight: 'bold' }}>S No</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Ch Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Video Title</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Video ID</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Views</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Likes</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Comments</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Upload Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Sponsored</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </thead>
            <TableBody>
              {filteredVideos.map((video, index) => (
                <TableRow key={video.id}>
                  <TableCell style={{ padding: '0.1px' }}>{index + 1}</TableCell> {/* Serial number */}
                  <TableCell style={{ padding: '0.1px' }}>{video.channel_name}</TableCell>
                  <TableCell style={{ padding: '0.1px' }}>{video.video_title ? video.video_title.slice(0, 15) : ""}</TableCell>



                  <TableCell style={{ padding: '0.1px' }}>
                    <a href={`https://www.youtube.com/watch?v=${video.video_id}`} target="_blank" rel="noopener noreferrer">
                      {video.video_id}
                    </a>
                  </TableCell>
                  <TableCell style={{ padding: '0.1px' }}>{video.video_views}</TableCell>
                  <TableCell style={{ padding: '0.1px' }}>{video.video_likes}</TableCell>
                  <TableCell style={{ padding: '0.1px' }}>{video.video_comments}</TableCell>
                  <TableCell style={{ padding: '0.1px' }}>{new Date(video.video_upload_date).toLocaleString()}</TableCell>


                  <TableCell style={{ padding: '0.1px' }}>{video.sponsored ? 'Yes' : 'No'}</TableCell>
                  <TableCell style={{ padding: '0.1px' }}>
                    <IconButton onClick={() => handleEditVideo(video)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleInfo(video)} aria-label="info">
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="edit-video-modal-title"
        aria-describedby="edit-video-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: '80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <IconButton onClick={() => setEditModalOpen(false)} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <h2 id="edit-video-modal-title">Edit Video</h2>
         
          <Box>
  <Typography variant="h6">Video Views</Typography>
  <TextField
    name="video_views"
    value={editVideoDetails.video_views}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
  />
</Box>
<Box>
  <Typography variant="h6">Video Likes</Typography>
  <TextField
    name="video_likes"
    value={editVideoDetails.video_likes}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
  />
</Box>
<Box>
  <Typography variant="h6">Video Dislikes</Typography>
  <TextField
    name="video_dislikes"
    value={editVideoDetails.video_dislikes}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
  />
</Box>
<Box>
  <Typography variant="h6">Video Comments</Typography>
  <TextField
    name="video_comments"
    value={editVideoDetails.video_comments}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
  />
</Box>
<Box>
  <Typography variant="h6">Video Length</Typography>
  <TextField
    name="video_length"
    value={editVideoDetails.video_length}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
  />
</Box>
<Box>
  <Typography variant="h6">Sponsored</Typography>
  <TextField
    name="sponsored"
    value={editVideoDetails.sponsored}
    onChange={handleEditInputChange}
    fullWidth
    margin="normal"
  />
</Box>

          <Button onClick={handleUpdateVideo} variant="contained" color="primary">Update Video</Button>
        </Box>
      </Modal>
      <Modal
        open={openInfoModal}
        onClose={handleCloseInfoModal}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          height: '80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          <IconButton onClick={handleCloseInfoModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button
  onClick={() => handleTabChange('Details')}
  style={{ color: selectedTab === 'Details' ? 'blue' : 'inherit' }}
>
  Details
</Button>
<Button
  onClick={() => handleTabChange('Documents')}
  style={{ color: selectedTab === 'Documents' ? 'blue' : 'inherit' }}
>
  Documents
</Button>
<Button
  onClick={() => handleTabChange('History')}
  style={{ color: selectedTab === 'History' ? 'blue' : 'inherit' }}
>
  History
</Button>



          </div>
          {selectedVideo && (
            <div>
              {selectedTab === 'Details' && (
                <Table style={{ marginTop: '5px' }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Channel ID:</TableCell>
                      <TableCell>{selectedVideo.channel}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Video Views:</TableCell>
                      <TableCell>{selectedVideo.video_views}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Video Likes:</TableCell>
                      <TableCell>{selectedVideo.video_likes}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Video Dislikes:</TableCell>
                      <TableCell>{selectedVideo.video_dislikes}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Video Comments:</TableCell>
                      <TableCell>{selectedVideo.video_comments}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Upload Date:</TableCell>
                      <TableCell>{selectedVideo.video_upload_date}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Video Length:</TableCell>
                      <TableCell>{selectedVideo.video_length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Sponsored:</TableCell>
                      <TableCell>{selectedVideo.sponsored ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Date Last Updated:</TableCell>
                      <TableCell>{selectedVideo.date_last_updated}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
              {selectedTab === 'Documents' && (
                <div>
                  {/* Document content goes here */}
                </div>
              )}
              {selectedTab === 'History' && (
  <div>
    <h3>Video History</h3>
    {loadingHistory ? (
      <div>Loading history...</div>
    ) : (
      <Table>
        <TableHead>
          <TableRow>
           
            <TableCell sx={{ fontWeight: 'bold' }}>Views</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Likes</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Favorite</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Comment</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Date Updated</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Video</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {videoHistory.map(historyItem => (
            <TableRow key={historyItem.id}>
             
              <TableCell>{historyItem.views}</TableCell>
              <TableCell>{historyItem.likes}</TableCell>
              <TableCell>{historyItem.favorite}</TableCell>
              <TableCell>{historyItem.comment}</TableCell>
              <TableCell>{historyItem.date_updated}</TableCell>
              <TableCell>{historyItem.video}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )}
  </div>
)}

            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Videos;
