import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer style={{ textAlign: 'center', position: 'fixed', bottom: 0, left: 0, width: '100%',padding: '0px 0', marginTop:"10px" }}>
       &copy; UBE Global 2023-{currentYear}
    </footer>
  );
};

export default Footer;
