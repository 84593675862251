import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, Button, Modal,Menu,MenuItem,Typography,IconButton} from '@mui/material';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/current`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      setCurrentUser(response.data);
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const handleAdminMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdminMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log('Logout button clicked');
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Conditionally render the header only if not on the login page
  if (location.pathname === '/login' || location.pathname === '/mailScreen' || location.pathname === '/passwordReset') {
    return null; // Don't render header for login, mailScreen, and passwordReset routes
  }

  return (
    <Box position="sticky" top={0} bgcolor="#1565C0" zIndex={1000}>
      <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Button component={NavLink} to="/home" style={{ color: 'white', marginRight: '10px' }}>Home</Button>
          <Button component={NavLink} to="/channels" style={{ color: 'white', marginRight: '10px' }}>Channels</Button>
          <Button component={NavLink} to="/videos" style={{ color: 'white', marginRight: '10px' }}>Videos</Button>
          <Button component={NavLink} to="/subscribers" style={{ color: 'white', marginRight: '10px' }}>Subscribers</Button>
          <Button component={NavLink} to="/artists" style={{ color: 'white', marginRight: '10px' }}>Artists</Button>
          <Button component={NavLink} to="/sponsors" style={{ color: 'white', marginRight: '10px' }}>Sponsors</Button>
          <Button component={NavLink} to="/vendors" style={{ color: 'white', marginRight: '10px' }}>Vendors</Button>
          <Button component={NavLink} to="/communities" style={{ color: 'white', marginRight: '10px' }}>Communities</Button>
          <Button component={NavLink} to="/reports" style={{ color: 'white', marginRight: '10px' }}>Reports</Button>
          <Button
            aria-controls="admin-menu"
            aria-haspopup="true"
            onClick={handleAdminMenuClick}
            style={{ color: 'white' }}
          >
            Admin
          </Button>
          <Menu
            id="admin-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleAdminMenuClose}
          >
            <MenuItem component={NavLink} to="/admin/users" onClick={handleAdminMenuClose}>Users</MenuItem>
            <MenuItem component={NavLink} to="/admin/location" onClick={handleAdminMenuClose}>Location</MenuItem>
            <MenuItem component={NavLink} to="/admin/fetch" onClick={handleAdminMenuClose}>Fetch</MenuItem>
            <MenuItem component={NavLink} to="/admin/permissions" onClick={handleAdminMenuClose}>Permissions</MenuItem>
            <MenuItem component={NavLink} to="/admin/login-history" onClick={handleAdminMenuClose}>Login History</MenuItem>
          </Menu>
        </Box>
        <Box>
          <Button onClick={handleOpenModal} style={{ color: 'white', marginRight: '10px' }}>Profile
            <AccountCircle />
          </Button>
          <Button onClick={handleLogout} style={{ color: 'white', marginRight: '10px' }}>Logout</Button>
        </Box>
      </Box>
      {/* User Details Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height:'80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
           <IconButton onClick={handleCloseModal} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
    {currentUser ? (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">First Name:</TableCell>
            <TableCell>{currentUser.first_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Last Name:</TableCell>
            <TableCell>{currentUser.last_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Email:</TableCell>
            <TableCell>{currentUser.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Phone Number 1:</TableCell>
            <TableCell>{currentUser.phone_number_1 || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Phone Number 2:</TableCell>
            <TableCell>{currentUser.phone_number_2 || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Address 1:</TableCell>
            <TableCell>{currentUser.address_1 || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Address 2:</TableCell>
            <TableCell>{currentUser.address_2 || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">City:</TableCell>
            <TableCell>{currentUser.city || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">State:</TableCell>
            <TableCell>{currentUser.state || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Country:</TableCell>
            <TableCell>{currentUser.country || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Pin Code:</TableCell>
            <TableCell>{currentUser.pin_code || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Location:</TableCell>
            <TableCell>{currentUser.location || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Status:</TableCell>
            <TableCell>{currentUser.status || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Last Updated:</TableCell>
            <TableCell>{currentUser.last_updated || 'N/A'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    ) : (
      <Typography variant="body1" gutterBottom>
        Loading...
      </Typography>
    )}
    <Button onClick={handleCloseModal}>Close</Button>
  </Box>
</Modal>

    </Box>
  );
};

export default Header;
