import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, Typography, Modal, CircularProgress, IconButton } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdminSearch from './AdminSearch';
import axios from 'axios';
import CancelIcon from '@mui/icons-material/Cancel';

const FetchByCheckbox = () => {
  const [channelChecked, setChannelChecked] = useState(false);
  const [videoChecked, setVideoChecked] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openChannelModal, setOpenChannelModal] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [videoCheckedState, setVideoCheckedState] = useState({});
  const [channelCheckedState, setChannelCheckedState] = useState({});
  const [fetchingVideoStats, setFetchingVideoStats] = useState(false);
  const [fetchingChannelStats, setFetchingChannelStats] = useState(false);
  const [fetchCompleted, setFetchCompleted] = useState(false);
  const [fetchMessage, setFetchMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    const fetchVideosAndChannels = async () => {
      try {
        setLoading(true); // Set loading state to true before the fetch starts
        const token = localStorage.getItem('token');
        
        if (!token) {
          throw new Error('Token not found. Please log in again.');
        }
        
        const [videosResponse, channelsResponse] = await Promise.all([
          axios.get(`${baseUrl}/api/videos/` , {
            headers: {
              'Authorization': `Token ${token}`
            }
          }),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/channels/`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          })
        ]);
        
        // Update the state with the fetched data
        setVideoList(videosResponse.data.results);
        setChannelList(channelsResponse.data.results);
        
      } catch (error) {
        console.error('Error fetching video or channel list:', error);
        // Optionally, provide user feedback here
        alert('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false); // Set loading state to false after the fetch completes
      }
    };
  
    fetchVideosAndChannels();
  }, []);
  

  const handleFetch = () => {
    if (channelChecked) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/channels/statistics/`)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }

    // Handle video fetch if needed

    // Log selected video IDs when the first checkbox is checked and fetch is clicked
    if (channelChecked) {
      console.log("Selected Video IDs:", selectedVideos);
    }

    // Set fetchCompleted to true after fetch
    setFetchCompleted(true);
    setFetchMessage('Fetch completed');
    setTimeout(() => {
      setFetchMessage('');
      setFetchCompleted(false);
    }, 5000);
  };

  const handleCancel = () => {
    setChannelChecked(false);
    setVideoChecked(false);
    setOpenVideoModal(false);
    setOpenChannelModal(false);
    setFetchCompleted(false); // Reset fetchCompleted state
    setFetchMessage('');
  };
 
  const handleVideoCheckboxChange = (videoId) => {
    setVideoCheckedState(prevState => ({
      ...prevState,
      [videoId]: !prevState[videoId],
    }));
    setOpenVideoModal(true);
  };

  const handleChannelCheckboxChange = (channelId) => {
    setChannelCheckedState(prevState => ({
      ...prevState,
      [channelId]: !prevState[channelId],
    }));
    setOpenChannelModal(true);

    console.log(`Channel ${channelId} checked:`, channelCheckedState[channelId]);
  };

  const handleVideoSelection = (videoId) => {
    setSelectedVideos(prevSelectedVideos => {
      if (prevSelectedVideos.includes(videoId)) {
        return prevSelectedVideos.filter(id => id !== videoId);
      } else {
        return [...prevSelectedVideos, videoId];
      }
    });
  };

  const handleChannelSelection = (channelId) => {
    setSelectedChannels(prevSelectedChannels => {
      let updatedSelectedChannels;
      if (prevSelectedChannels.includes(channelId)) {
        // If the channel is already selected, remove it from the list
        updatedSelectedChannels = prevSelectedChannels.filter(id => id !== channelId);
      } else {
        // If the channel is not selected, add it to the list
        updatedSelectedChannels = [...prevSelectedChannels, channelId];
      }
      return updatedSelectedChannels;
    });
  };

  const handleFetchVideos = () => {
    setFetchingVideoStats(true);
    const requestData = {
      video_ids: selectedVideos.map(videoId => videoId)
    };
  
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fetch_videos_statistics/`, requestData)
      .then(response => {
        console.log(response.data);
        setFetchingVideoStats(false);
        setFetchCompleted(true); // Set fetchCompleted to true after the fetch is completed
        setFetchMessage('Fetch completed');
        setTimeout(() => {
          setFetchMessage('');
          setFetchCompleted(false);
        }, 5000);
      })
      .catch(error => {
        console.error('Error fetching video statistics:', error);
        setFetchingVideoStats(false);
      });
  };
  
  
  const handleFetchChannels = () => {
    setFetchingChannelStats(true); // Set fetching flag to true
    const requestData = {
      channel_ids: selectedChannels.map(channelId => channelId),
      channel_id: selectedChannels[0] // Assuming you want to use the first selected channel ID
    };
  
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/channels/statistics/`, requestData)
      .then(response => {
        console.log('Channel statistics response:', response.data);
  
        // Add logic to save videos after fetching channel statistics
        const saveVideoRequestData = {
          channel_ids: selectedChannels // Assuming you want to save videos for all selected channels
        };
  
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/create-videos-from-channel/`, saveVideoRequestData)
          .then(saveResponse => {
            console.log('Video save response:', saveResponse.data);
            // Add additional logic here if needed
            setFetchingChannelStats(false); // Set fetching flag to false after fetch is completed
            setFetchCompleted(true); // Set fetchCompleted to true after the fetch is completed
            setFetchMessage('Fetch completed');
            setTimeout(() => {
              setFetchMessage('');
              setFetchCompleted(false);
            }, 5000);
          })
          .catch(saveError => {
            console.error('Error saving videos to the database:', saveError);
            setFetchingChannelStats(false); // Set fetching flag to false on error
          });
      })
      .catch(error => {
        console.error('Error fetching channel statistics:', error);
        setFetchingChannelStats(false); // Set fetching flag to false on error
      });
  };

  useEffect(() => {
    console.log("fetchMessage:", fetchMessage);
  }, [fetchMessage]);

  return (
    <div>
      <AdminSearch/>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Typography marginLeft={4}>Fetch BY:</Typography>
        <Checkbox color='primary' sx={{ marginLeft: '115px' }} checked={channelChecked} onChange={handleChannelCheckboxChange} />
        <Typography>Channel</Typography>
        <Checkbox color='primary' sx={{ marginLeft: '110px' }} checked={videoChecked} onChange={handleVideoCheckboxChange} />
        <Typography>Video</Typography>
      </Box>


      <Box display="flex" alignItems="center" marginTop={3}>
        <Typography marginLeft={4}>Select Video from List:</Typography>
        <Checkbox color='primary' sx={{ marginLeft: '30px' }} />
        <Typography>Uploaded Today</Typography>
        <Checkbox color='primary' sx={{ marginLeft: '30px' }} />
        <Typography>Uploaded Yesterday</Typography>
        <Checkbox color='primary' sx={{ marginLeft: '30px' }} />
        <Typography>Uploaded This Week</Typography>
        <Checkbox color='primary' sx={{ marginLeft: '30px' }} />
        <Typography>Uploaded Last Week</Typography>
      </Box>
      
      <Box display="flex" alignItems="center" marginTop={3}>
        <Typography>Last Updated :</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker label="Basic date picker" />
          </DemoContainer>
        </LocalizationProvider>
      </Box>

      <Modal
        open={openVideoModal}
        onClose={handleCancel}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height:'80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          {/* Close icon */}
          <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleCancel}>
            <CancelIcon />
          </IconButton>

          {/* Modal content for videos */}
          {fetchingVideoStats ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {videoList.map(video => (
                <div key={video.id}  style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    color="primary"
                    checked={videoCheckedState[video.video_id] || false}
                    onChange={() => {
                      handleVideoCheckboxChange(video.video_id);
                      handleVideoSelection(video.video_id); // Call handleVideoSelection here passing the video_id
                    }}
                  />
                  <Typography>{video.video_id}</Typography>
                </div>
              ))}
              <Button variant="contained" color="primary" onClick={handleFetchVideos}>Fetch</Button>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={openChannelModal}
        onClose={handleCancel}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height:'80vh',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          {/* Close icon */}
          <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleCancel}>
            <CancelIcon />
          </IconButton>

          {/* Modal content for channels */}
          {fetchingChannelStats ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {channelList.map(channel => (
                <div key={channel.id} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    color="primary"
                    checked={channelCheckedState[channel.channel_id] || false}
                    onChange={() => {
                      handleChannelCheckboxChange(channel.channel_id);
                      handleChannelSelection(channel.channel_id);
                    }}
                  />
                  <Typography>{channel.channel_name}</Typography>
                </div>
              ))}
              <Button variant="contained" color="primary" onClick={handleFetchChannels}>Fetch Channels</Button>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={fetchCompleted}
        onClose={() => setFetchCompleted(false)}
        aria-labelledby="fetch-completed-modal"
        aria-describedby="fetch-completed-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
          zIndex: 9999
        }}>
          <Typography variant="h6" id="fetch-completed-modal" gutterBottom>
            {fetchMessage}
          </Typography>
          <Typography id="fetch-completed-description">
            The fetch operation has been completed successfully.
          </Typography>
          <Button variant="contained" color="primary" onClick={() => setFetchCompleted(false)}>
            Close
          </Button>
        </Box>
      </Modal>

      <Box display="flex" alignItems="center" marginTop="150px" justifyContent="center">
        <Button variant="contained" color="primary" marginLeft="10px" marginRight="10px" onClick={handleFetch}>Fetch</Button>
        <Button variant="contained" color="primary" style={{ marginLeft: '50px' }} onClick={handleCancel}>Cancel</Button>
      </Box>
    </div>
  );
}

export default FetchByCheckbox;
